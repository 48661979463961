// import "sceditor/minified/themes/default.min.css";
// import "sceditor/minified/sceditor.min.js";
// import "sceditor/minified/icons/monocons.js";
// import "sceditor/minified/formats/bbcode.js";
import { useLocation } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import DocChatAI from "./docChatAI";
import ClauseLibaryChat from "./ClauseLibraryChat";
import dayjs from "dayjs";
import LegalConfirmModal from "../modals/LegalConfirmModal";
import React, { useEffect, useContext, useState, useRef } from "react";
import QChat from "./QChat";
import aiOrange from "../../assets/icons/ai_orange.svg";
import aiPurple from "../../assets/icons/ai_purple.svg";
// Adding changes regarding sceditor
// import $ from 'jquery';
// import 'sceditor';
// import 'sceditor/themes/default.min.css';
// Changes regarding SCEDITOR end

// import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Editor } from "@tinymce/tinymce-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import axios from "axios";
import { resolve } from "../../api/resolve";
import useAxios from "../../hooks/useAxios";
import LoadingScreen from "../loader/LoadingScreen";
import { toast } from "react-toastify";
import AppContext from "../../context/AppContext";
import { getDefaultLocale } from "react-datepicker";
import PermissionContext from "../../context/PermissionContext";
import TemplateNameModel from "../modals/TemplateNameModel";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import ClauseAI from "./clauseai";
import DeleteIcon from "../../assets/icons/Delete.svg";
import QbotIcon from "../../assets/icons/ai-icon.png";
import DefaultIcon from "../../assets/icons/defaulticon.png";
import DeleteModal from "../modals/DeleteModal";

// Your API key and endpoint URL (ideally, these should be retrieved securely from environment variables or a secure storage)
const AZURE_OPENAI_API_KEY = "4581c30403f847b7add57bbd9d324aba";
const AZURE_OPENAI_ENDPOINT =
  "https://q-chatgpt.openai.azure.com/openai/deployments/gpt-4o/chat/completions?api-version=2024-05-01-preview"; // e.g. https://<INSERT_RESOURCE_NAME_HERE>.openai.azure.com/openai/deployments/<INSERT_DEPLOYMENT_ID_HERE>/chat/completions?api-version=<INSERT_API_VERSION_HERE>

// https://<RESOURCE_NAME>.openai.azure.com/openai/deployments/<DEPLOYMENT_ID>/chat/completions?api-version=<API_VERSION>

const ai_request = (request, respondWith) => {
  const azureOpenAiOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "api-key": AZURE_OPENAI_API_KEY,
    },
    body: JSON.stringify({
      temperature: 0.7,
      max_tokens: 800,
      messages: [{ role: "user", content: request.prompt }],
    }),
  };
  respondWith.string((signal) =>
    window
      .fetch(AZURE_OPENAI_ENDPOINT, { signal, ...azureOpenAiOptions })
      .then(async (response) => {
        if (response) {
          const data = await response.json();
          if (data.error) {
            throw new Error(`${data.error.type}: ${data.error.message}`);
          } else if (response.ok) {
            return data?.choices[0]?.message?.content?.trim();
          }
        } else {
          throw new Error("Failed to communicate with the Azure OpenAI API");
        }
      })
  );
};

const CreateTemplate = () => {
  const [documentData, setDocumentData] = useState("");
  const [templateList, setTemplateList] = useState([]);
  const [defaultTemplateList, setDefaultTemplateList] = useState([]);
  const editorRef = useRef(null);
  const [clauseList, setClauseList] = useState([]);
  const [documentDataDefault, setDocumentDataDefault] = useState(() => {
    return localStorage.getItem("createTemplateContent") || "";
  });
  const [search, setSearch] = useState("");
  const [searchDefault, setSearchDefault] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [templateNameDefault, setTemplateNameDefault] = useState("");
  const [templateRemarks, setTemplateRemarks] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [errorMsgDefault, setErrorMsgDefault] = useState("");
  const [loading, setLoading] = useState(true);
  const [activeTab, setactiveTab] = useState("selectTemplate");
  // const [iframSrc, setIframeUrl] = useState("");
  const setIframeUrl = useState("");
  const [openTemplateNameModal, setOpenTemplateNameModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleteVersionModalOpen, setIsDeleteVersionModalOpen] =
    useState(false);
  const [deleteTemplateId, setDeleteTemplateId] = useState(null);
  const [deleteVersionId, setDeleteVersionId] = useState(null);
  const location = useLocation();
  const [apicalled, setApicalled] = useState(false);
  const [apiResponse, setApiResponse] = useState("");
  const [versionList, setVersionList] = useState([]);
  const [currentVersion, setCurrentVersion] = useState(0);
  const textareaRef = useRef();
  const [aiContent, setAiContent] = useState("");
  const [selectedText, setSelectedText] = useState("");

  const [isChatVisible, setIsChatVisible] = useState(false);

  const toggleChat = () => {
    setIsChatVisible((prevState) => !prevState); // Toggle the chat visibility
  };

  const {
    permissionList,
    getComapnyInfoApi,
    isValidCompany,
    updatePermissionList,
  } = useContext(PermissionContext);

  const {
    activeLink,
    setActiveLink,
    setPrevActiveLink,
    setShowDropdown,
    setTempSaveConfirmation,
    templateConfirmationOpen,
    setTemplateConfirmationOpen,
  } = useContext(AppContext);

  const baseURL = process.env.REACT_APP_BACKEND_BASE_URL;
  const api = useAxios();
  const { t } = useTranslation();

  const handleEditorInit = (evt, editor) => {
    editorRef.current = editor;
    editor.on("SelectionChange", () => {
      const selectedContent = editor.selection.getContent();
      setSelectedText(selectedContent);
    });
  };

  const replaceSelectedText = (text) => {
    if (editorRef.current) {
      editorRef.current.selection.setContent(text);
    }
  };

  const getTemplateListApi = async () => {
    const type = "template";
    const responceData = await resolve(
      api
        .get(`${baseURL}/contract/template/list/?type=${type}`)
        .then((res) => res.data)
    );
    if (responceData["data"]) {
      setTemplateList(responceData["data"].data);
    }
  };

  // const updateContent = () => {
  //   const content = window.sceditor.instance(textareaRef.current).val();
  //   setAiContent(content);
  // };

  const getTemplateSearchListApi = async (search) => {
    const responceData = await resolve(
      api
        .get(`${baseURL}/contract/template/list/search/?search=${search}`)
        .then((res) => res.data)
    );
    if (responceData["data"] && responceData["data"].results) {
      setTemplateList(responceData["data"].results);
    }
  };

  const templateListFetch = async (id) => {
    setCurrentVersion(id);
    const baseURL = process.env.REACT_APP_BACKEND_BASE_URL;
    console.log("REACT_APP_BACKEND_BASE_URL", baseURL);
    const url = `${baseURL}/contract/template/versions/${id}/`;
    console.log("Final URL", url);

    try {
      const res = await api.get(url);
      if (res.data) {
        setVersionList(res.data);
        setTemplateNameDefault(res.data[0].title);
      }
      return res.data;
    } catch (error) {
      console.error("Error fetching template list:", error);
    }
  };

  const handleTemplateClick = async (item) => {
    // setDocumentData("");
    setDocumentData("");
    setLoading(true);
    const res = await resolve(
      api
        .get(`${baseURL}/contract/template/list/view/${item.id}`)
        .then((res) => {
          setApiResponse(res["data"] + "");
          setApicalled(false);
          setApicalled(true);

          if (res["data"]) {
            // setDocumentDataDefault(responceData["data"] + "");
            setLoading(false);
          }

          if (res["error"]) {
            toast.error(t("common.Something went wrong"));
            // setLoading(false);
          }
          return res.data;
        })
    );
  };

  const deleteTemplate = async (item) => {
    const responceData = await resolve(
      api
        .get(`${baseURL}/contract/template/list/delete/${deleteTemplateId}`)
        .then((res) => res.data)
    );
    if (responceData["data"]) {
      toast.success(t("allModule.Template deleted successfully"));
      getTemplateListApi();
      getDefaultTemplateListApi();
    }

    if (responceData["error"]) {
      toast.error(t("allModule.Delete template failed"));
    }
    setIsDeleteModalOpen(false);
  };

  const deleteVersion = async (item) => {
    const responceData = await resolve(
      api
        .get(`${baseURL}/contract/template/Version/delete/${deleteVersionId}`)
        .then((res) => res.data)
    );
    if (responceData["data"]) {
      toast.success(t("allModule.Template Version deleted successfully"));
      getTemplateListApi();
      getDefaultTemplateListApi();
    }

    if (responceData["error"]) {
      toast.error(t("allModule.Delete template failed"));
    }
    setIsDeleteVersionModalOpen(false);
    setVersionList([]);
  };

  const addTableStyles = async (content) => {
    let modifiedContent =
      '<div style="font-family: Times New Roman; font-size: 11px;">' +
      content +
      "</div>";
    modifiedContent = modifiedContent.replace(
      /<table>/g,
      '<table style="border-collapse: collapse; margin: 0 auto;">'
    );

    // Replace <td> tags with style
    modifiedContent = modifiedContent.replace(
      /<td>/g,
      '<td style="border: 1px solid #000; empty-cells: show; min-width: 40px;">'
    );

    modifiedContent = modifiedContent.replace(
      /<iframe.*?data-youtube-id="(.*?)".*?<\/iframe>/g,
      '<a href="https://www.youtube.com/watch?v=$1" target="_blank"><img width="70" height="50" src="https://img.youtube.com/vi/$1/maxresdefault.jpg" alt="Video Thumbnail"></a>'
    );

    // Define regular expression to match img src attribute
    const imgRegex = /<img\s+[^>]*src="([^"]*)"[^>]*>/g;

    const promises = [];

    // Replace img src attribute with Base64
    modifiedContent.replace(imgRegex, (match, src) => {
      if (src.startsWith("emoticons/")) {
        // Convert image to Base64
        promises.push(convertImageToBase64(src, 700, 350, "emoji"));
        // Replace src attribute with Base64 string
        return match;
      } else if (src.startsWith("https://")) {
        promises.push(convertImageToBase64(src, 400, 200, "https"));
        return match;
      } else {
        promises.push(`<img width="500px" height="300px" src="${src}" />`);
        return match;
      }
    });

    const data = await Promise.all(promises);
    return modifiedContent.replace(imgRegex, () => data.shift());
  };

  // Function to convert image to Base64
  const convertImageToBase64 = (src, maxWidth, maxHeight, type) => {
    return new Promise((resolve, reject) => {
      if (type == "emoji") {
        const url = window.location.host;
        const protocol = window.location.protocol;
        src = `${protocol}//${url}/${src}`;
      }

      let convertedImage;
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          let img = new Image();
          img.onload = function () {
            // Calculate aspect ratio
            let aspectRatio = img.width / img.height;

            // Calculate new dimensions
            let newWidth = img.width;
            let newHeight = img.height;

            if (newWidth > maxWidth) {
              newWidth = maxWidth;
              newHeight = newWidth / aspectRatio;
            }

            if (newHeight > maxHeight) {
              newHeight = maxHeight;
              newWidth = newHeight * aspectRatio;
            }

            // Create canvas for resizing
            let canvas = document.createElement("canvas");
            canvas.width = newWidth;
            canvas.height = newHeight;

            // Resize image
            let ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, newWidth, newHeight);

            // Convert resized image to Base64
            let resizedBase64 = canvas.toDataURL("image/jpeg"); // You can change 'image/jpeg' to 'image/png' if needed
            convertedImage = `<img src="${resizedBase64}" width="${newWidth}" height="${newHeight}" />`;
            resolve(convertedImage);
          };
          img.src = reader.result;
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", src);
      xhr.responseType = "blob";
      xhr.send();
    });
  };

  const handleSaveTemplate = async () => {
    const content = documentData;
    setErrorMsg("");
    // const sceditorInstance = window.sceditor.instance(textareaRef.current);
    // const content = sceditorInstance.val();
    if (templateName === "") {
      setErrorMsg(t("allModule.Please provide document name"));
    } else if (content === "") {
      setErrorMsg(
        t("allModule.Please provide document content or choose template")
      );
    } else {
      setLoading(true);
      // const modifiedContent = await addTableStyles(content);
      const postData = {
        templateName: templateName,
        // content: modifiedContent,
        content: content,
        createTemplate: false,
        createDocuement: true,
        type: "template",
        fileType: "pdf",
      };
      const res = await resolve(
        api
          .post(`${baseURL}/contract/template/converter/`, postData)
          .then((res) => {
            // setApicalled(false);
            // setApicalled(true);
            setLoading(false);
            if (res["data"]) {
              toast.success(t("allModule.Document created successfully"));
              setTemplateName("");
              setDocumentData("");
              // setApiResponse("");
              setDocumentData("");
              setTempSaveConfirmation(false);
              localStorage.removeItem("createTemplateContent");
              setActiveLink("MyDocuments");
              setShowDropdown("");
              updatePermissionList(res["data"].User_permission[0]);
            }
            return res.data;
          })
          .catch((error) => {
            setLoading(false);
            setApicalled(false);
            setApicalled(true);
            toast.error(t("allModule.Create template failed"));
          })
      );
    }
  };

  const getDefaultTemplateListApi = async () => {
    const type = "template";
    const responceData = await resolve(
      api
        .get(`${baseURL}/contract/template/list/?type=${type}`)
        .then((res) => res.data)
    );
    if (responceData["data"]) {
      setDefaultTemplateList(responceData["data"].data);
    }
  };

  const getDefaultTemplateSearchListApi = async (search) => {
    const responceData = await resolve(
      api
        .get(
          `${baseURL}/contract/template/list/search/?search=${searchDefault}`
        )
        .then((res) => res.data)
    );
    if (responceData["data"] && responceData["data"].results) {
      setDefaultTemplateList(responceData["data"].results);
    }
  };

  const handleDefaultTemplateClick = async (item) => {
    setDocumentDataDefault("");
    setLoading(true);
    const res = await resolve(
      api
        .get(`${baseURL}/contract/template/list/view/${item.id}`)
        .then((res) => {
          setApiResponse(res["data"] + "");
          setApicalled(false);
          setApicalled(true);

          if (res["data"]) {
            // setDocumentDataDefault(responceData["data"] + "");
            setLoading(false);
          }

          if (res["error"]) {
            toast.error(t("common.Something went wrong"));
            // setLoading(false);
          }
          return res.data;
        })
    );
  };

  const handleSaveTemplateDefault = async () => {
    // const sceditorInstance = window.sceditor.instance(textareaRef.current);
    // const content = sceditorInstance.val();
    const content = documentData;
    setErrorMsg("");
    if (templateNameDefault === "") {
      setErrorMsgDefault(t("allModule.Please provide template name"));
    } else if (templateRemarks === "") {
      setErrorMsgDefault(t("allModule.Please provide template Remarks"));
    } else if (content === "<p><br></p>") {
      setErrorMsgDefault(
        t("allModule.Please provide template content or choose template")
      );
    } else {
      setErrorMsgDefault("");
      // const modifiedContent = await addTableStyles(content);
      setLoading(true);
      const postData = {
        templateName: templateNameDefault,
        // content: modifiedContent,
        content: content,
        createTemplate: true,
        createDocuement: false,
        type: "template",
        fileType: "docx",
        remarks: templateRemarks,
      };
      const res = await resolve(
        api
          .post(`${baseURL}/contract/template/converter/`, postData)
          .then((res) => {
            setApicalled(false);
            setApicalled(true);
            // setApicalled(false);
            // setApiResponse(res["data"] + "");
            setLoading(false);
            if (res["data"]) {
              toast.success(t("allModule.Template created successfully"));
              setTemplateNameDefault("");
              setTemplateRemarks("");
              setDocumentDataDefault("");
              setApiResponse("");
              localStorage.removeItem("createTemplateContent");
              getTemplateListApi();
              getDefaultTemplateListApi();
              updatePermissionList(res["data"].User_permission[0]);
            }
            return res.data;
          })
          .catch((error) => {
            setApiResponse(content);
            setLoading(false);
            setApicalled(false);
            setApicalled(true);
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              toast.error(error.response.data.message); // Displaying the custom error message
            }
          })
      );
    }
  };

  const handelTemplateversionDefault = async (item) => {
    const postData = {
      template_id: item.id,
    };
    const res = await api
      .post(`${baseURL}/contract/template/versions/default`, postData)
      .then((res) => {
        if (res.data) {
          setVersionList(res.data);
          toast.success(t("allModule.Template set as default successfully"));
        }
        return res.data;
      })
      .catch((error) => {
        toast.error(t("allModule.Set default template failed"));
      });
  };

  const handleVersionTemplateUpdate = async () => {
    // const sceditorInstance = window.sceditor.instance(textareaRef.current);
    // const content = sceditorInstance.val();
    const content = documentData;
    // sceditorInstance.val("");

    setErrorMsg("");
    if (templateNameDefault === "") {
      setErrorMsgDefault(t("allModule.Please provide template name"));
    } else if (templateRemarks === "") {
      setErrorMsgDefault(t("allModule.Please provide template Remarks"));
    } else if (content === "<p><br></p>") {
      setErrorMsgDefault(
        t("allModule.Please provide template content or choose template")
      );
    } else {
      setLoading(true);
      setErrorMsgDefault("");
      // const modifiedContent = await addTableStyles(content);
      const postData = {
        templateName: templateNameDefault,
        // content: modifiedContent,
        content: content,
        createTemplate: true,
        createDocuement: false,
        type: "template",
        fileType: "docx",
        template_id: currentVersion,
        remarks: templateRemarks,
      };
      const res = await resolve(
        api
          .post(`${baseURL}/contract/template/versions/`, postData)
          .then((res) => {
            setLoading(false);
            if (res["data"]) {
              toast.success(t("allModule.Template created successfully"));
              setTemplateNameDefault("");
              setTemplateRemarks("");
              setDocumentDataDefault("");
              setDocumentData("");
              setApiResponse("");
              // sceditorInstance.val("");
              setVersionList([]);
              setApicalled(false);
              setApicalled(true);
              localStorage.removeItem("createTemplateContent");
              getTemplateListApi();
              getDefaultTemplateListApi();
              updatePermissionList(res["data"].User_permission[0]);
            }
            return res.data;
          })
          .catch((error) => {
            setLoading(false);
            setApicalled(false);
            setApicalled(true);
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              toast.error(error.response.data.message); // Displaying the custom error message
            }
          })
      );
    }
  };

  // const handelVersionView = async (item) => {
  //   const sceditorInstance = window.sceditor.instance(textareaRef.current);
  //   sceditorInstance.val(item.html_content);
  // };

  const handelVersionView = async (item) => {
    if (editorRef.current) {
      // Set the content of the TinyMCE editor
      editorRef.current.setContent(item.html_content);
    } else {
      console.error("Editor instance is not available.");
    }
  };

  useEffect(() => {
    setLoading(true);
    getTemplateListApi();
    getDefaultTemplateListApi();
    getComapnyInfoApi();
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  // useEffect(() => {
  //   setTempSaveConfirmation(true);
  //   setVersionList([]);
  //   setTemplateNameDefault("");
  //   setTemplateRemarks("");
  //   setCurrentVersion(0);
  //   const delay = setTimeout(() => {
  //     if (textareaRef.current) {
  //       window.sceditor.create(textareaRef.current, {
  //         format: "xhtml",
  //         icons: "monocons",
  //         style: `${process.env.PUBLIC_URL}/sceditor.css`,
  //         emoticonsEnabled: true,
  //       });
  //     }
  //   }, 1000);
  //   return () => clearTimeout(delay);
  // }, []);

  useEffect(() => {
    setTempSaveConfirmation(true);
    setVersionList([]);
    setTemplateNameDefault("");
    setTemplateRemarks("");
    setCurrentVersion(0);

    const delay = setTimeout(() => {
      if (editorRef.current) {
        editorRef.current.setContent(""); // Initialize with empty content or any default content
      }
    }, 1000);

    return () => clearTimeout(delay);
  }, []);

  // useEffect(() => {
  //   if (textareaRef.current) {
  //     window.sceditor.create(textareaRef.current, {
  //       format: "xhtml",
  //       icons: "monocons",
  //       style: `${process.env.PUBLIC_URL}/sceditor.css`,
  //     });
  //     const sceditorInstance = window.sceditor.instance(textareaRef.current);
  //     sceditorInstance.val("");
  //   }
  //   setVersionList([]);
  //   setTemplateNameDefault("");
  //   setTemplateRemarks("");
  //   setCurrentVersion(0);
  // }, [activeTab]);

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.setContent(""); // Clear the content
    }
    setVersionList([]);
    setTemplateNameDefault("");
    setTemplateRemarks("");
    setCurrentVersion(0);
  }, [activeTab]);

  // const handleApiCall = () => {
  //   if (textareaRef.current) {
  //     window.sceditor.create(textareaRef.current, {
  //       format: "xhtml",
  //       icons: "monocons",
  //       style: `${process.env.PUBLIC_URL}/sceditor.css`,
  //     });
  //     const sceditorInstance = window.sceditor.instance(textareaRef.current);
  //     apiResponse === ""
  //       ? sceditorInstance.val("")
  //       : sceditorInstance.val(apiResponse);
  //     setLoading(false);
  //   }
  // };

  const handleApiCall = () => {
    if (editorRef.current) {
      editorRef.current.setContent(apiResponse === "" ? "" : apiResponse); // Set content based on API response
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(false);
    const timeout = setTimeout(() => {
      if (apicalled == true) {
        handleApiCall();
        setApicalled(false);
      }
    }, 500);

    return () => clearTimeout(timeout);
  }, [apicalled]);

  const getClauseListApi = async () => {
    try {
      const responseData = await resolve(
        api.get(`${baseURL}/contract/list/clause/`).then((res) => res.data)
      );
      if (responseData["data"] && responseData["data"].results) {
        const updatedList = responseData["data"].results.map((item) => {
          let clauseTextArray;
          try {
            clauseTextArray = JSON.parse(item.clause_text.replace(/'/g, '"'));
          } catch (error) {
            console.error("Error parsing clause_text:", error);
            clauseTextArray = [];
          }
          return { ...item, clause_text: clauseTextArray };
        });
        setClauseList(updatedList);
      }
    } catch (error) {
      console.error("Error fetching clause library list:", error);
    }
  };

  const clauseOptions = clauseList.map((clause) => ({
    name: clause.clause_name,
    text: clause.clause_text,
  }));

  function formatClausesForEditor(clauses) {
    return clauses
      .map(
        (clause) =>
          `Clause name: "${clause.name}"\nClause text: "${clause.text.join(
            " "
          )}"`
      )
      .join("\n\n");
  }

  // Function to accept changes only for selected text
  function acceptChangesForSelection(editor) {
    editor.undoManager.transact(function () {
      const selectedContent = editor.selection.getContent({ format: "html" });
      if (!selectedContent) {
        console.log("No text selected. Cannot accept changes.");
        return;
      }

      // Create a temporary container to parse the HTML content
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = selectedContent;

      // Function to recursively remove deleted text and accept inserted text
      function processNode(node) {
        node.childNodes.forEach((child) => {
          if (child.nodeType === Node.ELEMENT_NODE) {
            // Handle strikethrough text (deleted text) and remove it
            if (
              child.style.color === "red" &&
              child.style.textDecoration === "line-through"
            ) {
              child.remove();
            } else if (
              child.style.color === "red" &&
              child.style.textDecoration === "underline"
            ) {
              // Convert redlined text (added text) to normal text
              child.style.color = "";
              child.style.textDecoration = "";
            }

            // Handle list items (li) and bullet styling
            if (
              child.tagName.toLowerCase() === "li" &&
              child.style.color === "red"
            ) {
              // Remove redline styles from list item and bullet
              child.style.color = "";
              child.style.textDecoration = "";
            }

            // Recursively process child nodes
            processNode(child);
          }
        });
      }

      // Process the content to accept changes
      processNode(tempDiv);

      // Get the cleaned HTML content
      const newContent = tempDiv.innerHTML;

      // Replace the selected content with the updated content
      editor.selection.setContent(newContent);

      // Log changes for debugging
      console.log(
        "Accepted changes for selected text. New content:",
        newContent
      );
    });
  }

  // Function to reject changes only for selected text
  function rejectChangesForSelection(editor) {
    editor.undoManager.transact(function () {
      const selectedContent = editor.selection.getContent({ format: "html" });
      if (!selectedContent) {
        console.log("No text selected. Cannot reject changes.");
        return;
      }

      // Remove redlined text (added text)
      let newContent = selectedContent.replace(
        /<span style="color: red; text-decoration: underline;">(.*?)<\/span>/g,
        ""
      );

      // Convert strikethrough text (deleted text) to normal text
      newContent = newContent.replace(
        /<span style="color: red; text-decoration: line-through;">(.*?)<\/span>/g,
        "$1"
      );

      // Replace the selected content with the updated content
      editor.selection.setContent(newContent);

      // Log changes for debugging
      console.log(
        "Rejected changes for selected text. New content:",
        newContent
      );
    });
  }

  const formattedClauses = formatClausesForEditor(clauseOptions);

  return (
    <>
      <div className="text-primary text-base font-normal font-sans mt_set">
        <div className="main_head">
          <h1 className="text-4xl font-bold text_c">
            {t("menu.Create from Template")}
          </h1>
        </div>

        {!isValidCompany ? (
          <div className="flex justify-center items-center h-96 flex-column ">
            <h3 className="text-2xl font-bold text_c">
              {t("allModule.Please update your company info.")}
            </h3>
          </div>
        ) : permissionList["custom_template_on"] == 1 ? (
          <div className="w-full items-center generate_docuement">
            <div className="row">
              <div
                className="col-md-12"
                style={{ display: "flex", marginBottom: "10px" }}
              >
                <button
                  style={{
                    border: "none",
                    marginRight: 10,
                    color: "gray",
                    background: "transparent",
                    fontWeight: 500,
                    fontSize: 16,
                    cursor: "pointer",
                    padding: "5px",
                    borderBottom:
                      activeTab == "selectTemplate"
                        ? "2px solid black"
                        : "none",
                  }}
                  onClick={() => {
                    setactiveTab("selectTemplate");
                  }}
                >
                  {t("menu.Create from Template")}
                </button>
                <button
                  style={{
                    marginRight: 10,
                    border: "none",
                    color: "gray",
                    background: "transparent",
                    fontWeight: 500,
                    fontSize: 16,
                    cursor: "pointer",
                    padding: "5px",
                    borderBottom:
                      activeTab == "createTemplate"
                        ? "2px solid black"
                        : "none",
                  }}
                  onClick={() => {
                    setactiveTab("createTemplate");
                  }}
                >
                  {t("allModule.Create a Template")}
                </button>
              </div>

              {activeTab == "selectTemplate" && (
                <>
                  {permissionList.custom_template_on === false ? (
                    <div className="flex justify-center items-center h-96 flex-column w-full">
                      <h3 className="text-2xl font-bold text_c">
                        {t(
                          "allmodule.You dont have permission to access this page"
                        )}
                      </h3>
                    </div>
                  ) : permissionList.custom_template_allotted_cnt > 0 &&
                    permissionList.custom_template_remaing_cnt === 0 ? (
                    <div className="flex justify-center items-center h-96 flex-column w-full">
                      <h3 className="text-2xl font-bold text_c">
                        {t(
                          "allModule.You have no more limit to create template."
                        )}
                      </h3>
                    </div>
                  ) : (
                    <>
                      <div className="col-md-9">
                        {loading ? (
                          <LoadingScreen />
                        ) : (
                          <>
                            <div className="bottom_area">
                              {/* <CKEditor
                                editor={ClassicEditor}
                                data={documentData}
                                onReady={(editor) => { }}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  setDocumentData(data);
                                }}  
                              />
                              <>
                                <form action="" method="post">
                                  <div>
                                    <textarea
                                      ref={textareaRef}
                                      id="example"
                                      style={{
                                        height: "70vh",
                                        width: "100%",
                                      }}
                                    >
                                      {documentData}
                                    </textarea>
                                  </div>
                                </form>
                              </>*/}

                              <Editor
                                tinymceScriptSrc={`${process.env.PUBLIC_URL}/tinymce/tinymce.min.js`}
                                // tinymceScriptSrc="https://cdn.tiny.cloud/1/zi2ytxohu4e3dhkgcqh3z4hlmorokuwbj5a31lz5s5xsj9ge/tinymce/6/tinymce.min.js"
                                value={documentData}
                                onInit={handleEditorInit}
                                init={{
                                  selector: "textarea#editor",
                                  license_key: "gpl",
                                  height: "70vh",
                                  menubar: false,
                                  branding: false,
                                  statusbar: false,
                                  browser_spellcheck: true,
                                  promotion: false,
                                  plugins: [
                                    // "anchor",
                                    "autolink",
                                    "charmap",
                                    // "codesample",
                                    "emoticons",
                                    "image",
                                    "link",
                                    "lists",
                                    "media",
                                    "searchreplace",
                                    "table",
                                    "visualblocks",
                                    "wordcount",
                                    "checklist",
                                    "mediaembed",
                                    "casechange",
                                    "export",
                                    "formatpainter",
                                    // "pageembed",
                                    "a11ychecker",
                                    "tinymcespellchecker",
                                    "permanentpen",
                                    "powerpaste",
                                    "advtable",
                                    "advcode",
                                    "editimage",
                                    "advtemplate",
                                    "ai",
                                    "mentions",
                                    // "tinycomments",
                                    "tableofcontents",
                                    // "footnotes",
                                    "mergetags",
                                    "autocorrect",
                                    "typography",
                                    "inlinecss",
                                    "markdown",
                                    "advlist",
                                    "insertdatetime",
                                    "autosave",
                                    "code",
                                    "directionality",
                                    "fullscreen",
                                    "paste",
                                    "preview",
                                    // "tinydrive",
                                    "quickbars",
                                    // "save",
                                    "aisummary",
                                    "aichat",
                                    "clausecheck",
                                    "customdropdown",
                                  ],
                                  toolbar:
                                    "undo redo | aichat customdropdown | blocks fontsizeinput | bold italic underline strikethrough superscript subscript | forecolor backcolor formatpainter  | removeformat alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image media table | lineheight outdent indent | charmap emoticons checklist | code fullscreen preview print | pagebreak mergetags | addcomment showcomments | ltr rtl casechange | spellcheckdialog a11ycheck | chatButton | acceptChanges rejectChanges | ClearButton",
                                  content_style:
                                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                  quickbars_selection_toolbar:
                                    "aichat customdropdown | acceptChanges rejectChanges | bold italic | blocks | quicklink blockquote",
                                  setup: function (editor) {
                                    // Add Accept Changes button
                                    editor.ui.registry.addIcon(
                                      "AcceptIconSvg",
                                      '<svg width="24" height="24" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg"><defs><style>.cls-1 { fill: #699f4c; fill-rule: evenodd; }</style></defs><path class="cls-1" d="M800,510a30,30,0,1,1,30-30A30,30,0,0,1,800,510Zm-16.986-23.235a3.484,3.484,0,0,1,0-4.9l1.766-1.756a3.185,3.185,0,0,1,4.574.051l3.12,3.237a1.592,1.592,0,0,0,2.311,0l15.9-16.39a3.187,3.187,0,0,1,4.6-.027L817,468.714a3.482,3.482,0,0,1,0,4.846l-21.109,21.451a3.185,3.185,0,0,1-4.552.03Z" transform="translate(-770 -450)"/></svg>'
                                    );

                                    editor.ui.registry.addButton(
                                      "acceptChanges",
                                      {
                                        text: "",
                                        icon: "AcceptIconSvg",
                                        tooltip: "Accept Changes",
                                        onAction: function () {
                                          editor.undoManager.transact(
                                            function () {
                                              acceptChangesForSelection(editor);
                                            }
                                          );
                                        },
                                      }
                                    );

                                    editor.ui.registry.addIcon(
                                      "RejectIconSvg",
                                      '<svg width="28" height="28" viewBox="0 0 512 512" version="1.1" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><style type="text/css">.st0{fill:#FF0000;} .st1{fill:none;stroke:#FF0000;stroke-width:32;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}</style><g id="Layer_1"/><g id="Layer_2"><g><path class="st0" d="M263.24,43.5c-117.36,0-212.5,95.14-212.5,212.5s95.14,212.5,212.5,212.5s212.5-95.14,212.5-212.5 S380.6,43.5,263.24,43.5z M367.83,298.36c17.18,17.18,17.18,45.04,0,62.23v0c-17.18,17.18-45.04,17.18-62.23,0l-42.36-42.36 l-42.36,42.36c-17.18,17.18-45.04,17.18-62.23,0v0c-17.18-17.18-17.18-45.04,0-62.23L201.01,256l-42.36-42.36 c-17.18-17.18-17.18-45.04,0-62.23v0c17.18-17.18,45.04-17.18,62.23,0l42.36,42.36l42.36-42.36c17.18-17.18,45.04-17.18,62.23,0v0 c17.18,17.18,17.18,45.04,0,62.23L325.46,256L367.83,298.36z"/></g></g></g></svg>'
                                    );

                                    // Add Reject Changes button
                                    editor.ui.registry.addButton(
                                      "rejectChanges",
                                      {
                                        text: "",
                                        icon: "RejectIconSvg",
                                        tooltip: "Reject Changes",
                                        onAction: function () {
                                          editor.undoManager.transact(
                                            function () {
                                              rejectChangesForSelection(editor);
                                            }
                                          );
                                        },
                                      }
                                    );

                                    editor.ui.registry.addIcon(
                                      "ClearIcon",
                                      '<svg width="24" height="24" viewBox="0 0 512 512" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><g><path d="M332.571,140.81l-16.665,16.648c-41.547-24.17-95.688-18.468-131.288,17.116l153.521,153.52c35.583-35.592,41.294-89.732,17.115-131.278l16.666-16.657L332.571,140.81z" fill="#000000"/><path d="M0,299.943c0,0,9.585,18.251,28.989,45.303c14.864-9,51.042-31.664,90.012-61.833l6.324,6.332c0,0-42.7,39.908-82.445,74.058c5.748,7.405,12.09,15.206,19.026,23.278c21.441-18.549,45.223-39.781,62.447-57.014l8.756,4.865c0,0-29.692,31.691-60.914,63.788c6.558,7.279,13.584,14.701,21.079,22.196c18.954,18.954,37.494,34.692,54.158,47.448c16.576-19.124,44.934-52.032,53.69-63.482l9.712,11.665c0,0-26.052,39.42-40.502,68.401c26.269,17.935,43.907,26.331,43.907,26.331c31.331-54.654,114.695-169.502,114.695-169.502L172.997,185.825C172.997,185.825,98.372,248.208,0,299.943z" fill="#000000"/><rect x="334.304" y="62.854" transform="matrix(0.7071 -0.7071 0.7071 0.7071 67.7361 328.4017)" fill="#000000" width="191.963" height="39.163"/></g></svg>'
                                    );

                                    // Add Clear button
                                    editor.ui.registry.addButton(
                                      "ClearButton",
                                      {
                                        text: "",
                                        icon: "ClearIcon",
                                        tooltip: "Clear Text",
                                        onAction: function () {
                                          // Create a custom TinyMCE dialog for confirmation
                                          editor.windowManager.open({
                                            title: "Confirmation",
                                            size: "normal", // Make the dialog responsive relative to the editor
                                            body: {
                                              type: "panel",
                                              items: [
                                                {
                                                  type: "htmlpanel",
                                                  html: "<p>Are you sure you want to clear all the text?</p>",
                                                },
                                              ],
                                            },
                                            buttons: [
                                              {
                                                type: "cancel",
                                                text: "Cancel",
                                                buttonType: "secondary",
                                                name: "cancel",
                                              },
                                              {
                                                type: "submit",
                                                text: "Confirm",
                                                buttonType: "primary",
                                                primary: true,
                                                name: "confirm",
                                              },
                                            ],
                                            onSubmit: function (dialogApi) {
                                              // Clear content upon confirmation
                                              editor.undoManager.transact(
                                                function () {
                                                  editor.setContent(""); // Clear content
                                                }
                                              );
                                              dialogApi.close();
                                            },
                                          });

                                          // Add custom styles for the dialog buttons
                                          const styleElement =
                                            document.createElement("style");
                                          styleElement.innerHTML = `
                                      /* Target the Cancel button */
                                      .tox-dialog__footer .tox-button.tox-button--secondary,
                                      .tox-dialog__footer .tox-button.tox-button--secondary.tox-button--secondary--icon {
                                        background-color: #e0720c !important;
                                        border-color: #e0720c !important;
                                        color: white !important;
                                      }
                                
                                      /* Target the Confirm button */
                                      .tox-dialog__footer .tox-button.tox-button--primary,
                                      .tox-dialog__footer .tox-button.tox-button--primary.tox-button--primary--icon {
                                        background-color: #310128 !important;
                                        border-color: #310128 !important;
                                        color: white !important;
                                      }
                                
                                      /* Hover states */
                                      .tox-dialog__footer .tox-button.tox-button--primary:hover,
                                      .tox-dialog__footer .tox-button.tox-button--primary.tox-button--primary--icon:hover {
                                        background-color: #4a0147 !important; /* Slightly lighter hover color for Confirm */
                                      }
                                      .tox-dialog__footer .tox-button.tox-button--secondary:hover,
                                      .tox-dialog__footer .tox-button.tox-button--secondary.tox-button--secondary--icon:hover {
                                        background-color: #ff8a1f !important; /* Slightly lighter hover color for Cancel */
                                      }
                                    `;
                                          document.head.appendChild(
                                            styleElement
                                          ); // Append styles to the document head
                                        },
                                      }
                                    );
                                  },

                                  formattedClauses: formattedClauses, // Pass formatted clauses to TinyMCE
                                  azure_openai_config: {
                                    endpoint:
                                      "https://q-chatgpt.openai.azure.com/openai/deployments/gpt-4o-mini/chat/completions?api-version=2024-05-01-preview",
                                    apiKey: "4581c30403f847b7add57bbd9d324aba",
                                  },
                                  font_formats:
                                    "Arial=arial,helvetica,sans-serif; Courier New=courier new,courier,monospace; Georgia=georgia,palatino,serif; Tahoma=tahoma,arial,helvetica,sans-serif; Times New Roman=times new roman,times,serif; Verdana=verdana,geneva,sans-serif",
                                  fontsize_formats:
                                    "8pt 10pt 12pt 14pt 18pt 24pt 36pt",
                                  tinycomments_mode: "embedded",
                                  tinycomments_author: "Author name",
                                  mergetags_list: [
                                    {
                                      value: "First.Name",
                                      title: "First Name",
                                    },
                                    { value: "Email", title: "Email" },
                                  ],
                                  ai_request,
                                  ai_shortcuts: [
                                    {
                                      title: "Summarize",
                                      prompt:
                                        "Convert this to screenplay format.",
                                      selection: true,
                                    },
                                    {
                                      title: "Stage play",
                                      prompt:
                                        "Convert this to stage play format.",
                                      selection: true,
                                    },
                                    {
                                      title: "Classical",
                                      subprompts: [
                                        {
                                          title: "Dialogue",
                                          prompt:
                                            "Convert this to a Socratic dialogue.",
                                          selection: true,
                                        },
                                        {
                                          title: "Homeric",
                                          prompt:
                                            "Convert this to a Classical Epic.",
                                          selection: true,
                                        },
                                      ],
                                    },
                                    {
                                      title: "Celtic",
                                      subprompts: [
                                        {
                                          title: "Bardic",
                                          prompt:
                                            "Convert this to Bardic verse.",
                                          selection: true,
                                        },
                                        {
                                          title: "Filí",
                                          prompt:
                                            "Convert this to Filí-an verse.",
                                          selection: true,
                                        },
                                      ],
                                    },
                                  ],
                                  image_advtab: true,
                                  typography_rules: [
                                    "common/punctuation/quote",
                                    "en-US/dash/main",
                                    "common/nbsp/afterParagraphMark",
                                    "common/nbsp/afterSectionMark",
                                    "common/nbsp/afterShortWord",
                                    "common/nbsp/beforeShortLastNumber",
                                    "common/nbsp/beforeShortLastWord",
                                    "common/nbsp/dpi",
                                    "common/punctuation/apostrophe",
                                    "common/space/delBeforePunctuation",
                                    "common/space/afterComma",
                                    "common/space/afterColon",
                                    "common/space/afterExclamationMark",
                                    "common/space/afterQuestionMark",
                                    "common/space/afterSemicolon",
                                    "common/space/beforeBracket",
                                    "common/space/bracket",
                                    "common/space/delBeforeDot",
                                    "common/space/squareBracket",
                                    "common/number/mathSigns",
                                    "common/number/times",
                                    "common/number/fraction",
                                    "common/symbols/arrow",
                                    "common/symbols/cf",
                                    "common/symbols/copy",
                                    "common/punctuation/delDoublePunctuation",
                                    "common/punctuation/hellip",
                                  ],
                                  typography_ignore: ["code"],
                                  toolbar_mode: "wrap",
                                  contextmenu: "link image imagetools table",
                                  table_advtab: true,
                                  table_appearance_options: true,
                                  table_default_attributes: { border: "1" },
                                  table_default_styles: { width: "100%" },
                                }}
                                onEditorChange={(content) => {
                                  setDocumentData(content);
                                }}
                              />
                            </div>
                            <div className="flex justify-end">
                              <p className="error_msg">{errorMsg}</p>
                            </div>

                            <div className="mt-5 flex justify-end">
                              <input
                                type="text"
                                value={templateName}
                                onChange={(e) =>
                                  setTemplateName(e.target.value)
                                }
                                className="border-2 border-primary rounded-lg outline-none p-1 pl-2"
                                placeholder={t("allModule.Enter Document Name")}
                              />
                              <button
                                onClick={handleSaveTemplate}
                                className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3 ml-5"
                              >
                                {t("common.Save")}
                              </button>
                              <button
                                onClick={toggleChat}
                                className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3 ml-2 flex items-center justify-center"
                                data-tip="AI Assistant and Document Chat"
                              >
                                <img
                                  src={isChatVisible ? aiOrange : aiPurple}
                                  alt="AI"
                                  className="tab-icon"
                                  style={{
                                    width: "40px",
                                    height: "24px",
                                  }}
                                />
                              </button>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="col-md-3">
                        {isChatVisible ? (
                          <div
                            style={{
                              flex: "3",
                              display: "fixed",
                              height: "70vh",
                              // padding: ,
                            }}
                          >
                            <QChat
                              documentData={documentData}
                              setDocumentData={setDocumentData}
                              selectedText={selectedText}
                              replaceSelectedText={replaceSelectedText}
                              className="chat-window"
                            />
                          </div>
                        ) : (
                          <div>
                            <h4 className="text-xl font-bold  mb-2 text_c ">
                              {t("allModule.Select Template")}
                            </h4>
                            <div className="seachbox mb-2">
                              <input
                                type="text"
                                className="border-2 border-primary rounded-lg outline-none p-1 pl-2 w-2/3"
                                placeholder={t("allModule.Search a Template")}
                                onChange={(e) => {
                                  setSearch(e.target.value);
                                }}
                                onKeyUp={(e) => {
                                  if (
                                    e.key === "Enter" ||
                                    e.target.value === ""
                                  ) {
                                    getTemplateSearchListApi(search);
                                  }
                                }}
                                value={search}
                              />
                              <button
                                className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3"
                                onClick={(e) =>
                                  getTemplateSearchListApi(search)
                                }
                              >
                                {t("allModule.Search")}
                              </button>
                            </div>
                            <div className="template_list_container pad_0">
                              {loading == false &&
                                templateList.length > 0 &&
                                templateList.map((item, index) => {
                                  return (
                                    <>
                                      <ReactTooltip
                                        backgroundColor="#400835"
                                        textColor="#ffffff"
                                      />
                                      <div
                                        key={index}
                                        onClick={(e) => {
                                          handleTemplateClick(item);
                                        }}
                                        className="template_box"
                                      >
                                        <div className="template_box_head">
                                          <h1 className="text-2xl font-bold text_c">
                                            {" "}
                                            {item.title}{" "}
                                          </h1>
                                        </div>
                                        <div className="template_box_body">
                                          <strong>
                                            {t("allModule.Created on")}
                                          </strong>
                                          <span className="text-base font-normal text_c">
                                            {" "}
                                            {dayjs(item.created_at).format(
                                              "dddd, MMM D, YYYY HH:mm"
                                            )}
                                          </span>
                                        </div>
                                        <button
                                          className="btn btn_theme btn-sm mr-1 mt-8 wh_spab "
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            setDeleteTemplateId(item.id);
                                            setIsDeleteModalOpen(true);
                                          }}
                                          data-tip={t(
                                            "constant.Delete document"
                                          )}
                                        >
                                          <img
                                            src={DeleteIcon}
                                            className="ml-0.5"
                                            width={20}
                                            alt="Delete Document"
                                          />
                                        </button>
                                      </div>
                                    </>
                                  );
                                })}

                              {loading == false && templateList.length == 0 && (
                                <div className="flex justify-center items-center h-96 flex-column w-full">
                                  <h3 className="text-2xl font-bold text_c">
                                    {t("allModule.No Template Found")}
                                  </h3>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </>
              )}

              {activeTab == "createTemplate" && (
                <>
                  {permissionList.custom_template_on === false ? (
                    <div className="flex justify-center items-center h-96 flex-column w-full">
                      <h3 className="text-2xl font-bold text_c">
                        {t(
                          "allModule.You dont have permission to access this page"
                        )}
                      </h3>
                    </div>
                  ) : permissionList.custom_template_allotted_cnt > 0 &&
                    permissionList.custom_template_remaing_cnt === 0 ? (
                    <div className="flex justify-center items-center h-96 flex-column w-full">
                      <h3 className="text-2xl font-bold text_c">
                        {t(
                          "allModule.You have no more limit to create template."
                        )}
                      </h3>
                    </div>
                  ) : (
                    <>
                      <div className="col-md-9">
                        {loading ? (
                          <LoadingScreen />
                        ) : (
                          <>
                            <div className="bottom_area">
                              {/* <CKEditor
                                editor={ClassicEditor}
                                data={documentDataDefault}
                                onReady={(editor) => { }}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  setDocumentDataDefault(data);
                                }}
                              /> 
                              <form action="" method="post">
                                <div>
                                  <textarea
                                    ref={textareaRef}
                                    id="example1"
                                    style={{
                                      height: "70vh",
                                      width: "100%",
                                    }}
                                  />
                                </div>
                              </form>*/}

                              <Editor
                                tinymceScriptSrc={`${process.env.PUBLIC_URL}/tinymce/tinymce.min.js`}
                                // tinymceScriptSrc="https://cdn.tiny.cloud/1/zi2ytxohu4e3dhkgcqh3z4hlmorokuwbj5a31lz5s5xsj9ge/tinymce/6/tinymce.min.js"
                                value={documentData}
                                onInit={handleEditorInit}
                                init={{
                                  selector: "textarea#editor",
                                  license_key: "gpl",
                                  height: "70vh",
                                  menubar: false,
                                  branding: false,
                                  statusbar: false,
                                  browser_spellcheck: true,
                                  promotion: false,
                                  plugins: [
                                    // "anchor",
                                    "autolink",
                                    "charmap",
                                    // "codesample",
                                    "emoticons",
                                    "image",
                                    "link",
                                    "lists",
                                    "media",
                                    "searchreplace",
                                    "table",
                                    "visualblocks",
                                    "wordcount",
                                    "checklist",
                                    "mediaembed",
                                    "casechange",
                                    "export",
                                    "formatpainter",
                                    // "pageembed",
                                    "a11ychecker",
                                    "tinymcespellchecker",
                                    "permanentpen",
                                    "powerpaste",
                                    "advtable",
                                    "advcode",
                                    "editimage",
                                    "advtemplate",
                                    "ai",
                                    "mentions",
                                    // "tinycomments",
                                    "tableofcontents",
                                    // "footnotes",
                                    "mergetags",
                                    "autocorrect",
                                    "typography",
                                    "inlinecss",
                                    "markdown",
                                    "advlist",
                                    "insertdatetime",
                                    "autosave",
                                    "code",
                                    "directionality",
                                    "fullscreen",
                                    "paste",
                                    "preview",
                                    // "tinydrive",
                                    "quickbars",
                                    // "save",
                                    "aisummary",
                                    "aichat",
                                    "clausecheck",
                                    "customdropdown",
                                  ],
                                  toolbar:
                                    "undo redo | aichat customdropdown | blocks fontsizeinput | bold italic underline strikethrough superscript subscript | forecolor backcolor formatpainter  | removeformat alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image media table | lineheight outdent indent | charmap emoticons checklist | code fullscreen preview print | pagebreak mergetags | addcomment showcomments | ltr rtl casechange | spellcheckdialog a11ycheck | chatButton | acceptChanges rejectChanges | ClearButton",
                                  content_style:
                                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                  quickbars_selection_toolbar:
                                    "aichat customdropdown | acceptChanges rejectChanges | bold italic | blocks | quicklink blockquote",
                                  setup: function (editor) {
                                    // Add Accept Changes button
                                    editor.ui.registry.addIcon(
                                      "AcceptIconSvg",
                                      '<svg width="24" height="24" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg"><defs><style>.cls-1 { fill: #699f4c; fill-rule: evenodd; }</style></defs><path class="cls-1" d="M800,510a30,30,0,1,1,30-30A30,30,0,0,1,800,510Zm-16.986-23.235a3.484,3.484,0,0,1,0-4.9l1.766-1.756a3.185,3.185,0,0,1,4.574.051l3.12,3.237a1.592,1.592,0,0,0,2.311,0l15.9-16.39a3.187,3.187,0,0,1,4.6-.027L817,468.714a3.482,3.482,0,0,1,0,4.846l-21.109,21.451a3.185,3.185,0,0,1-4.552.03Z" transform="translate(-770 -450)"/></svg>'
                                    );

                                    editor.ui.registry.addButton(
                                      "acceptChanges",
                                      {
                                        text: "",
                                        icon: "AcceptIconSvg",
                                        tooltip: "Accept Changes",
                                        onAction: function () {
                                          editor.undoManager.transact(
                                            function () {
                                              acceptChangesForSelection(editor);
                                            }
                                          );
                                        },
                                      }
                                    );

                                    editor.ui.registry.addIcon(
                                      "RejectIconSvg",
                                      '<svg width="28" height="28" viewBox="0 0 512 512" version="1.1" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><style type="text/css">.st0{fill:#FF0000;} .st1{fill:none;stroke:#FF0000;stroke-width:32;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}</style><g id="Layer_1"/><g id="Layer_2"><g><path class="st0" d="M263.24,43.5c-117.36,0-212.5,95.14-212.5,212.5s95.14,212.5,212.5,212.5s212.5-95.14,212.5-212.5 S380.6,43.5,263.24,43.5z M367.83,298.36c17.18,17.18,17.18,45.04,0,62.23v0c-17.18,17.18-45.04,17.18-62.23,0l-42.36-42.36 l-42.36,42.36c-17.18,17.18-45.04,17.18-62.23,0v0c-17.18-17.18-17.18-45.04,0-62.23L201.01,256l-42.36-42.36 c-17.18-17.18-17.18-45.04,0-62.23v0c17.18-17.18,45.04-17.18,62.23,0l42.36,42.36l42.36-42.36c17.18-17.18,45.04-17.18,62.23,0v0 c17.18,17.18,17.18,45.04,0,62.23L325.46,256L367.83,298.36z"/></g></g></g></svg>'
                                    );

                                    // Add Reject Changes button
                                    editor.ui.registry.addButton(
                                      "rejectChanges",
                                      {
                                        text: "",
                                        icon: "RejectIconSvg",
                                        tooltip: "Reject Changes",
                                        onAction: function () {
                                          editor.undoManager.transact(
                                            function () {
                                              rejectChangesForSelection(editor);
                                            }
                                          );
                                        },
                                      }
                                    );

                                    editor.ui.registry.addIcon(
                                      "ClearIcon",
                                      '<svg width="24" height="24" viewBox="0 0 512 512" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><g><path d="M332.571,140.81l-16.665,16.648c-41.547-24.17-95.688-18.468-131.288,17.116l153.521,153.52c35.583-35.592,41.294-89.732,17.115-131.278l16.666-16.657L332.571,140.81z" fill="#000000"/><path d="M0,299.943c0,0,9.585,18.251,28.989,45.303c14.864-9,51.042-31.664,90.012-61.833l6.324,6.332c0,0-42.7,39.908-82.445,74.058c5.748,7.405,12.09,15.206,19.026,23.278c21.441-18.549,45.223-39.781,62.447-57.014l8.756,4.865c0,0-29.692,31.691-60.914,63.788c6.558,7.279,13.584,14.701,21.079,22.196c18.954,18.954,37.494,34.692,54.158,47.448c16.576-19.124,44.934-52.032,53.69-63.482l9.712,11.665c0,0-26.052,39.42-40.502,68.401c26.269,17.935,43.907,26.331,43.907,26.331c31.331-54.654,114.695-169.502,114.695-169.502L172.997,185.825C172.997,185.825,98.372,248.208,0,299.943z" fill="#000000"/><rect x="334.304" y="62.854" transform="matrix(0.7071 -0.7071 0.7071 0.7071 67.7361 328.4017)" fill="#000000" width="191.963" height="39.163"/></g></svg>'
                                    );

                                    // Add Clear button
                                    editor.ui.registry.addButton(
                                      "ClearButton",
                                      {
                                        text: "",
                                        icon: "ClearIcon",
                                        tooltip: "Clear Text",
                                        onAction: function () {
                                          // Create a custom TinyMCE dialog for confirmation
                                          editor.windowManager.open({
                                            title: "Confirmation",
                                            size: "normal", // Make the dialog responsive relative to the editor
                                            body: {
                                              type: "panel",
                                              items: [
                                                {
                                                  type: "htmlpanel",
                                                  html: "<p>Are you sure you want to clear all the text?</p>",
                                                },
                                              ],
                                            },
                                            buttons: [
                                              {
                                                type: "cancel",
                                                text: "Cancel",
                                                buttonType: "secondary",
                                                name: "cancel",
                                              },
                                              {
                                                type: "submit",
                                                text: "Confirm",
                                                buttonType: "primary",
                                                primary: true,
                                                name: "confirm",
                                              },
                                            ],
                                            onSubmit: function (dialogApi) {
                                              // Clear content upon confirmation
                                              editor.undoManager.transact(
                                                function () {
                                                  editor.setContent(""); // Clear content
                                                }
                                              );
                                              dialogApi.close();
                                            },
                                          });

                                          // Add custom styles for the dialog buttons
                                          const styleElement =
                                            document.createElement("style");
                                          styleElement.innerHTML = `
                                      /* Target the Cancel button */
                                      .tox-dialog__footer .tox-button.tox-button--secondary,
                                      .tox-dialog__footer .tox-button.tox-button--secondary.tox-button--secondary--icon {
                                        background-color: #e0720c !important;
                                        border-color: #e0720c !important;
                                        color: white !important;
                                      }
                                
                                      /* Target the Confirm button */
                                      .tox-dialog__footer .tox-button.tox-button--primary,
                                      .tox-dialog__footer .tox-button.tox-button--primary.tox-button--primary--icon {
                                        background-color: #310128 !important;
                                        border-color: #310128 !important;
                                        color: white !important;
                                      }
                                
                                      /* Hover states */
                                      .tox-dialog__footer .tox-button.tox-button--primary:hover,
                                      .tox-dialog__footer .tox-button.tox-button--primary.tox-button--primary--icon:hover {
                                        background-color: #4a0147 !important; /* Slightly lighter hover color for Confirm */
                                      }
                                      .tox-dialog__footer .tox-button.tox-button--secondary:hover,
                                      .tox-dialog__footer .tox-button.tox-button--secondary.tox-button--secondary--icon:hover {
                                        background-color: #ff8a1f !important; /* Slightly lighter hover color for Cancel */
                                      }
                                    `;
                                          document.head.appendChild(
                                            styleElement
                                          ); // Append styles to the document head
                                        },
                                      }
                                    );
                                  },

                                  formattedClauses: formattedClauses, // Pass formatted clauses to TinyMCE
                                  azure_openai_config: {
                                    endpoint:
                                      "https://q-chatgpt.openai.azure.com/openai/deployments/gpt-4o-mini/chat/completions?api-version=2024-05-01-preview",
                                    apiKey: "4581c30403f847b7add57bbd9d324aba",
                                  },
                                  font_formats:
                                    "Arial=arial,helvetica,sans-serif; Courier New=courier new,courier,monospace; Georgia=georgia,palatino,serif; Tahoma=tahoma,arial,helvetica,sans-serif; Times New Roman=times new roman,times,serif; Verdana=verdana,geneva,sans-serif",
                                  fontsize_formats:
                                    "8pt 10pt 12pt 14pt 18pt 24pt 36pt",
                                  tinycomments_mode: "embedded",
                                  tinycomments_author: "Author name",
                                  mergetags_list: [
                                    {
                                      value: "First.Name",
                                      title: "First Name",
                                    },
                                    { value: "Email", title: "Email" },
                                  ],
                                  ai_request,
                                  ai_shortcuts: [
                                    {
                                      title: "Summarize",
                                      prompt:
                                        "Convert this to screenplay format.",
                                      selection: true,
                                    },
                                    {
                                      title: "Stage play",
                                      prompt:
                                        "Convert this to stage play format.",
                                      selection: true,
                                    },
                                    {
                                      title: "Classical",
                                      subprompts: [
                                        {
                                          title: "Dialogue",
                                          prompt:
                                            "Convert this to a Socratic dialogue.",
                                          selection: true,
                                        },
                                        {
                                          title: "Homeric",
                                          prompt:
                                            "Convert this to a Classical Epic.",
                                          selection: true,
                                        },
                                      ],
                                    },
                                    {
                                      title: "Celtic",
                                      subprompts: [
                                        {
                                          title: "Bardic",
                                          prompt:
                                            "Convert this to Bardic verse.",
                                          selection: true,
                                        },
                                        {
                                          title: "Filí",
                                          prompt:
                                            "Convert this to Filí-an verse.",
                                          selection: true,
                                        },
                                      ],
                                    },
                                  ],
                                  image_advtab: true,
                                  typography_rules: [
                                    "common/punctuation/quote",
                                    "en-US/dash/main",
                                    "common/nbsp/afterParagraphMark",
                                    "common/nbsp/afterSectionMark",
                                    "common/nbsp/afterShortWord",
                                    "common/nbsp/beforeShortLastNumber",
                                    "common/nbsp/beforeShortLastWord",
                                    "common/nbsp/dpi",
                                    "common/punctuation/apostrophe",
                                    "common/space/delBeforePunctuation",
                                    "common/space/afterComma",
                                    "common/space/afterColon",
                                    "common/space/afterExclamationMark",
                                    "common/space/afterQuestionMark",
                                    "common/space/afterSemicolon",
                                    "common/space/beforeBracket",
                                    "common/space/bracket",
                                    "common/space/delBeforeDot",
                                    "common/space/squareBracket",
                                    "common/number/mathSigns",
                                    "common/number/times",
                                    "common/number/fraction",
                                    "common/symbols/arrow",
                                    "common/symbols/cf",
                                    "common/symbols/copy",
                                    "common/punctuation/delDoublePunctuation",
                                    "common/punctuation/hellip",
                                  ],
                                  typography_ignore: ["code"],
                                  toolbar_mode: "wrap",
                                  contextmenu: "link image imagetools table",
                                  table_advtab: true,
                                  table_appearance_options: true,
                                  table_default_attributes: { border: "1" },
                                  table_default_styles: { width: "100%" },
                                }}
                                onEditorChange={(content) => {
                                  setDocumentData(content);
                                }}
                              />
                            </div>
                            <div className="flex justify-end">
                              <p className="error_msg">{errorMsgDefault}</p>
                            </div>

                            <div className="mt-5 flex justify-end">
                              <div className="flex items-center">
                                <div className="mt-5 mb-1 flex justify-end">
                                  {/* <div
                                    className="flex items-center mr-5"
                                    style={{ marginTop: "-22px" }}
                                  >
                                    <DocChatAI
                                      aiContent={aiContent}
                                      updateContent={() =>
                                        setAiContent(documentData)
                                      }
                                      className="btn pb-1 ml-1 mr-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3"
                                    />
                                  </div> */}
                                  {/* <div
                                    className="flex items-center mr-5"
                                    style={{ marginTop: "-22px" }}
                                  >
                                    <ClauseLibaryChat
                                      aiContent={aiContent}
                                      updateContent={() =>
                                        setAiContent(documentData)
                                      }
                                      className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3"
                                    />
                                  </div> */}
                                </div>
                                {versionList.length > 0 ? (
                                  <div className="flex items-center">
                                    <input
                                      type="text"
                                      value={templateNameDefault}
                                      onChange={(e) =>
                                        setTemplateNameDefault(e.target.value)
                                      }
                                      className="border-2 border-primary rounded-lg outline-none p-1 pl-2 mr-2"
                                      placeholder={t(
                                        "allModule.Save Template as"
                                      )}
                                      disabled
                                    />
                                    <input
                                      type="text"
                                      maxLength="100"
                                      value={templateRemarks}
                                      onChange={(e) =>
                                        setTemplateRemarks(e.target.value)
                                      }
                                      className="border-2  border-primary rounded-lg outline-none p-1 pl-2"
                                      placeholder={t(
                                        "allModule.Remarks for this version"
                                      )}
                                    />
                                    <button
                                      onClick={handleVersionTemplateUpdate}
                                      className="btn pb-1 pt-0.5 pl-2 pr-6 ml-2 text_c font-bold rounded-lg btn_theme3 ml-5"
                                    >
                                      {t("common.Update")}
                                    </button>
                                    <button
                                      onClick={toggleChat}
                                      className="btn pb-1 pt-0.5 pl-2 text_c font-bold rounded-lg btn_theme3 ml-2 flex items-center"
                                      data-tip="AI Assistant and Document Chat"
                                    >
                                      <img
                                        src={
                                          isChatVisible ? aiOrange : aiPurple
                                        }
                                        alt="AI"
                                        className="tab-icon"
                                        style={{
                                          width: "40px",
                                          height: "24px",
                                        }}
                                      />
                                    </button>
                                  </div>
                                ) : (
                                  <div className="mb-4 flex items-center">
                                    <input
                                      type="text"
                                      value={templateNameDefault}
                                      onChange={(e) =>
                                        setTemplateNameDefault(e.target.value)
                                      }
                                      className="border-2 border-primary rounded-lg outline-none p-1 pl-2 ml-2"
                                      placeholder={t(
                                        "allModule.Save Template as"
                                      )}
                                    />
                                    <input
                                      type="text"
                                      maxLength="100"
                                      value={templateRemarks}
                                      onChange={(e) =>
                                        setTemplateRemarks(e.target.value)
                                      }
                                      className="border-2 ml-2 border-primary rounded-lg outline-none p-1 pl-2"
                                      placeholder={t(
                                        "allModule.Remarks for this version"
                                      )}
                                    />
                                    <button
                                      onClick={handleSaveTemplateDefault}
                                      className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3 ml-5"
                                    >
                                      {t("common.Save")}
                                    </button>
                                    <button
                                      onClick={toggleChat}
                                      className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3 ml-2 flex items-center justify-center"
                                      data-tip="AI Assistant and Document Chat"
                                    >
                                      <img
                                        src={
                                          isChatVisible ? aiOrange : aiPurple
                                        }
                                        alt="AI"
                                        className="tab-icon"
                                        style={{
                                          width: "40px",
                                          height: "24px",
                                        }}
                                      />
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="col-md-3">
                        {isChatVisible ? (
                          <div
                            style={{
                              flex: "3",
                              display: "fixed",
                              height: "70vh",
                              // padding: ,
                            }}
                          >
                            <QChat
                              documentData={documentData}
                              setDocumentData={setDocumentData}
                              selectedText={selectedText}
                              replaceSelectedText={replaceSelectedText}
                              className="chat-window"
                            />
                          </div>
                        ) : versionList.length > 0 ? (
                          <div>
                            <h4 className="text-xl font-bold  mb-2 text_c ">
                              {t("allModule.Select Version")}
                            </h4>

                            <div className="template_list_container pad_0">
                              {loading == false &&
                                versionList.length > 0 &&
                                versionList.map((item, index) => {
                                  return (
                                    <div
                                      key={index}
                                      onClick={(e) => {
                                        handelVersionView(item);
                                      }}
                                      style={
                                        index === 0
                                          ? { border: "3px solid #310128" }
                                          : {}
                                      }
                                      className="template_box"
                                    >
                                      <div className="template_box_head">
                                        <h1 className="text-2xl font-bold text_c">
                                          {" "}
                                          {item.title} V_{item.version_no}{" "}
                                        </h1>
                                      </div>
                                      <div className="template_box_body">
                                        <strong>
                                          {t("allModule.Created on")}
                                        </strong>
                                        <span className="text-base font-normal text_c">
                                          {" "}
                                          {dayjs(
                                            item.version_created_at
                                          ).format("dddd, MMM D, YYYY HH:mm")}
                                        </span>
                                        <div>
                                          <strong>
                                            {t("allModule.Remarks")}
                                            {": "}
                                          </strong>

                                          <span className="text-base font-normal text_c">
                                            {item.remarks}
                                          </span>
                                        </div>
                                      </div>
                                      {versionList.length == 1 ? (
                                        <>
                                          <ReactTooltip
                                            backgroundColor="#400835"
                                            textColor="#ffffff"
                                          />
                                          <button
                                            className="btn btn_theme btn-sm mr-3 mt-10 wh_spab"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              handelTemplateversionDefault(
                                                item
                                              );
                                            }}
                                            disabled
                                            data-tip={t(
                                              "constant.Default Template"
                                            )}
                                          >
                                            <img
                                              src={DefaultIcon}
                                              className="ml-0.5"
                                              width={20}
                                              alt="Make Default Template"
                                            />
                                          </button>
                                          <button
                                            className="btn btn_theme btn-sm pl-4 ml-1 mr-3 mt-10 wh_spab "
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              // setDeleteVersionId(item.id);
                                              // setIsDeleteVersionModalOpen(true);
                                            }}
                                            disabled
                                            data-tip={t(
                                              "constant.Default Template is not allowed to delete"
                                            )}
                                          >
                                            <img
                                              src={DeleteIcon}
                                              className="ml-0.5"
                                              width={20}
                                              alt="Delete Template Version"
                                            />
                                          </button>
                                        </>
                                      ) : (
                                        <>
                                          <ReactTooltip
                                            backgroundColor="#400835"
                                            textColor="#ffffff"
                                          />
                                          <button
                                            className="btn btn_theme btn-sm mr-3 mt-10 wh_spab"
                                            disabled={index === 0}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              handelTemplateversionDefault(
                                                item
                                              );
                                            }}
                                            data-tip={
                                              index === 0
                                                ? t("constant.Default Template")
                                                : t(
                                                    "constant.Make Default Template"
                                                  )
                                            }
                                          >
                                            <img
                                              src={DefaultIcon}
                                              className="ml-0.5"
                                              width={20}
                                              alt="Make Default Template"
                                            />
                                          </button>
                                          <button
                                            className="btn btn_theme btn-sm pl-4 ml-1 mr-3 mt-10 wh_spab "
                                            disabled={index === 0}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              setDeleteVersionId(item.id);
                                              setIsDeleteVersionModalOpen(true);
                                            }}
                                            data-tip={
                                              index === 0
                                                ? t(
                                                    "constant.Default Template is not allowed to delete"
                                                  )
                                                : t(
                                                    "constant.Delete Template Version"
                                                  )
                                            }
                                          >
                                            <img
                                              src={DeleteIcon}
                                              className="ml-0.5"
                                              width={20}
                                              alt="Delete Template Version"
                                            />
                                          </button>
                                        </>
                                      )}
                                    </div>
                                  );
                                })}

                              {loading == false &&
                                defaultTemplateList.length == 0 && (
                                  <div className="flex justify-center items-center h-96 flex-column w-full">
                                    <h3 className="text-2xl font-bold text_c">
                                      {t("allModule.No Template Found")}
                                    </h3>
                                  </div>
                                )}
                            </div>
                          </div>
                        ) : (
                          <div>
                            <h4 className="text-xl font-bold  mb-2 text_c ">
                              {t("allModule.Select Template")}
                            </h4>
                            <div className="seachbox mb-2">
                              <input
                                type="text"
                                className="border-2 border-primary rounded-lg outline-none p-1 pl-2 w-2/3"
                                placeholder={t("allModule.Search a Template")}
                                onChange={(e) => {
                                  setSearchDefault(e.target.value);
                                }}
                                onKeyUp={(e) => {
                                  if (
                                    e.key === "Enter" ||
                                    e.target.value === ""
                                  ) {
                                    getDefaultTemplateSearchListApi(search);
                                  }
                                }}
                                value={searchDefault}
                              />
                              <button
                                className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3"
                                onClick={(e) =>
                                  getDefaultTemplateSearchListApi(search)
                                }
                              >
                                {t("allModule.Search")}
                              </button>
                            </div>
                            <div className="template_list_container pad_0">
                              {loading == false &&
                                defaultTemplateList.length > 0 &&
                                defaultTemplateList.map((item, index) => {
                                  return (
                                    <>
                                      <ReactTooltip
                                        backgroundColor="#400835"
                                        textColor="#ffffff"
                                      />
                                      <div
                                        key={index}
                                        onClick={(e) => {
                                          handleDefaultTemplateClick(item);
                                          templateListFetch(item.id);
                                        }}
                                        className="template_box"
                                      >
                                        <div className="template_box_head">
                                          <h1 className="text-2xl font-bold text_c">
                                            {" "}
                                            {item.title}{" "}
                                          </h1>
                                        </div>
                                        <div className="template_box_body">
                                          <strong>
                                            {t("allModule.Created on")}
                                          </strong>
                                          <span className="text-base font-normal text_c">
                                            {" "}
                                            {dayjs(item.created_at).format(
                                              "dddd, MMM D, YYYY HH:mm"
                                            )}
                                          </span>
                                        </div>
                                        <button
                                          className="btn btn_theme btn-sm mr-1 mt-8 wh_spab "
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            setDeleteTemplateId(item.id);
                                            setIsDeleteModalOpen(true);
                                          }}
                                          data-tip={t(
                                            "constant.Delete document"
                                          )}
                                        >
                                          <img
                                            src={DeleteIcon}
                                            className="ml-0.5"
                                            width={20}
                                            alt="Delete Document"
                                          />
                                        </button>
                                      </div>
                                    </>
                                  );
                                })}

                              {loading == false &&
                                defaultTemplateList.length == 0 && (
                                  <div className="flex justify-center items-center h-96 flex-column w-full">
                                    <h3 className="text-2xl font-bold text_c">
                                      {t("allModule.No Template Found")}
                                    </h3>
                                  </div>
                                )}
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        ) : (
          <div className="flex justify-center items-center h-96 flex-column ">
            <h3 className="text-2xl font-bold text_c">
              {t("allModule.You dont have permission to access this page")}
            </h3>
          </div>
        )}

        <ReactTooltip backgroundColor="#400835" textColor="#ffffff" />

        <DeleteModal
          title="Delete Template"
          bodyText="Are you sure you want to delete template?"
          isDeleteModalOpen={isDeleteModalOpen}
          setIsDeleteModalOpen={setIsDeleteModalOpen}
          deleteTemplate={deleteTemplate}
        />

        <DeleteModal
          title="Delete Template Version"
          bodyText="Are you sure you want to delete template version?"
          isDeleteModalOpen={isDeleteVersionModalOpen}
          setIsDeleteModalOpen={setIsDeleteVersionModalOpen}
          deleteVersion={deleteVersion}
        />

        <LegalConfirmModal
          title={t("allModule.Confirmation")}
          bodyText={t(
            "allModule.Please save your changes before moving out of this page!"
          )}
          isConfirmModalOpen={templateConfirmationOpen}
          setIsConfirmModalOpen={setTemplateConfirmationOpen}
          setTempSaveConfirmation={setTempSaveConfirmation}
          activeLink={activeLink}
          setPrevActiveLink={setPrevActiveLink}
          // deleteDocument={deleteDocument}
        />

        <TemplateNameModel
          isOpen={openTemplateNameModal}
          setIsOpen={setOpenTemplateNameModal}
          selectedTemplate={selectedTemplate}
          setIframeUrl={setIframeUrl}
          setType="legal template"
        />
      </div>
    </>
  );
};

export default CreateTemplate;
