import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import { AppProvider } from '../context/AppContext';
import { AuthProvider } from '../context/AuthContext';
import { FormProvider } from '../context/FormContext';
import { DocumentProvider } from '../context/DocumentContext';
import { PermissionProvider } from '../context/PermissionContext';
import Login from '../components/login/Login';
import ForgotPassword from '../components/forgot-password/ForgotPassword';

import ResetPassword from '../components/reset-password/ResetPassword';
import Dashboard from '../components/dashboard/Dashboard';
import RoutedSearch from "../components/dashboard/RoutedSearch";
import NotFoundComponent from '../components/not-found/NotFoundComponent';
import WorkflowToSign from '../components/contracts/WorkflowToSign';
import HelpModuleModal from '../components/help-module/HelpModule'
import QRSignature from '../components/signaturePad/QRSignature';
import ThankYouForSigning from '../components/contracts/ThankYouForSigning';
import VerifyEmail from '../components/verify/VerifyEmail';

import Test from '../components/Test'
import AadharSignature from '../components/signaturePad/AadharSignature';
import AadharSignatureResponce from '../components/signaturePad/AadharSignatureResponce';
// import HomePlanList from '../components/Plan/HomePlanList';
// import RegisterBusinessUser from '../components/Plan/RegisterBusinessUser';
import PaymentSuccess from '../components/payment/PaymentSuccess';
import PaymentFailed from '../components/payment/PaymentFailed';
import FinalDocPreview from '../components/contracts/FinalDocPreview';
import AadharSucess from '../components/settings/AadharSucess';
import AadharFailed from '../components/settings/AadharFailed';
import RoutedSearchlegacy from '../components/dashboard/RoutedSearchlegacy';
import RoutedSearchNormal from '../components/dashboard/RoutedSearchNormal';
import HomePage from '../components/homepage/Homepage';


const index = () => {
  return (
    <Router>

      <AuthProvider>

        <AppProvider>
        <DocumentProvider>
          <FormProvider>
            
              <PermissionProvider>
            
                <Routes>
                  <Route path='/' element={<Login title='Login' />} />
                  <Route
                    path='/forgot-password'
                    element={<ForgotPassword title='Forgot-Password' />}
                  />

                  <Route
                    path='/workflow/:recptId'
                    element={<WorkflowToSign />}
                  />

                  {/* <Route
                    path='/help'
                    element={<HelpModuleModal />}
                  /> */}                 


                  <Route
                    path='/workflow-thankyou'
                    element={<ThankYouForSigning />}
                  />

                  <Route
                    path='/verify-email/:token'
                    element={<VerifyEmail />}
                  />

                  <Route
                    path='/reset-password/:token'
                    element={<ResetPassword title='Reset-Password' />}
                  />

                  <Route
                    path='/test'
                    element={<Test />}
                  />



                  <Route
                    path='/aadhar-signature/:recptId'
                    element={<AadharSignature />}
                  />

                  <Route
                    path='/aadhar-signature-responce'
                    element={<AadharSignatureResponce />}
                  />

                  <Route
                    path='/success/:plan_id/:session_id'
                    element={<PaymentSuccess />}
                  />

                  <Route
                    path='/failure'
                    element={<PaymentFailed />}
                  />

                  <Route
                    path='/aadhar-success'
                    element={<AadharSucess />}
                  />

                  <Route
                    path='/aadhar-failure'
                    element={<AadharFailed />}
                  />




                  <Route
                    path='/qr-signature/:recptId/:qrcode_id'
                    element={<QRSignature />}
                  />

                  <Route
                    path='/document-preview/:docId'
                    element={<FinalDocPreview />}
                  />
                  <Route
                    path='/home'
                    element={<HomePage />}
                  />


                  <Route
                    path='/dashboard'
                    element={
                      <PrivateRoute>
                        <Dashboard title='Dashboard' />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path='/create-document'
                    element={
                      <PrivateRoute>
                        <Dashboard title='Create a Document' />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path='/upload-documents'
                    element={
                      <PrivateRoute>
                        <Dashboard title='Upload Documents' />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path='/upload-O365-documents'
                    element={
                      <PrivateRoute>
                        <Dashboard title='Upload O-365 Documents' />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path='/generate-document'
                    element={
                      <PrivateRoute>
                        <Dashboard title='Generate Document' />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path='/GenerateDocumentUpload'
                    element={
                      <PrivateRoute>
                        <Dashboard title='Generate Document Upload' />
                      </PrivateRoute>
                    }
                  />


                  <Route
                    path='/create-template'
                    element={
                      <PrivateRoute>
                        <Dashboard title='Create from Template' />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path='/template-library'
                    element={
                      <PrivateRoute>
                        <Dashboard title='Select from Template Library' />
                      </PrivateRoute>
                    }
                    
                  />

                  <Route
                    path='/Qflow'
                    element={
                      <PrivateRoute>
                        <Dashboard title='Qflow' />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path='/Qbot'
                    element={
                      <PrivateRoute>
                        <Dashboard title='Qbot' />
                      </PrivateRoute>
                    }
                  />
                <Route
                    path='/Marketplace'
                    element={
                      <PrivateRoute>
                        <Dashboard title='Marketplace' />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path='/AdminMarketplace'
                    element={
                      <PrivateRoute>
                        <Dashboard title='Marketplace for Admin' />
                      </PrivateRoute>
                    }
                  />


                  <Route
                    path='/contact-book/:name'
                    element={
                      <PrivateRoute>
                        <Dashboard title='contact-book' />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path='/my-documents/:na'
                    element={
                      <PrivateRoute>
                        <Dashboard title='My Documents' />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path='/apply-signature/:name'
                    element={
                      <PrivateRoute>
                        <Dashboard title='Apply Signature' />
                      </PrivateRoute>
                    }
                  />
                   <Route
                    path='/office-365-setting'
                    element={
                      <PrivateRoute>
                        <Dashboard title='Office 365 Setting' />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path='/stamps-seals'
                    element={
                      <PrivateRoute>
                        <Dashboard title='Stamps Seals' />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path='/stamps-seals/:name'
                    element={
                      <PrivateRoute>
                        <Dashboard title='Stamps Seals' />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path='/ai-review/:name'
                    element={
                      <PrivateRoute>
                        <Dashboard title='AI-Review' />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path='/playrite/:name'
                    element={
                      <PrivateRoute>
                        <Dashboard title='Playrite' />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path='/account'
                    element={
                      <PrivateRoute>
                        <Dashboard title='Account' />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path='/account/:name'
                    element={
                      <PrivateRoute>
                        <Dashboard title='Account' />
                      </PrivateRoute>
                    }
                  />

{/*<Route
                    path='/account/TaskList'
                    element={
                      <PrivateRoute>
                        <Dashboard title='TaskList' />
                      </PrivateRoute>
                    }
                  />*/}
                  <Route
                    path='/help'
                    element={
                      <PrivateRoute>
                        <Dashboard title='Help' />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path='/Bots'
                    element={
                      <PrivateRoute>
                        <Dashboard title='Bots' />
                      </PrivateRoute>
                    }
                  />
                   
                  <Route
                    path='/office-365-setting'
                    element={
                      <PrivateRoute>
                        <Dashboard title='Office 365 Setting' />
                      </PrivateRoute>
                    }
                  />
                  
                  {/* legacy routes starts:- */}
                  <Route
                    path="/homepagelegacy"
                    element={
                      <PrivateRoute>
                        <Dashboard title="homepagelegacy" />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/Searchlegacy"
                    element={
                    <PrivateRoute>
                      <Dashboard title="Search" />
                    </PrivateRoute>
                  }
                  />
                  <Route
                    path="/searchlegacy/:id"
                    element={<RoutedSearchlegacy title="Search" />}
                  />
                  <Route
                    path="/Searchnormal/:id"
                    element={<RoutedSearchNormal title="Searchnormal" />}
                  />
                  <Route
                    path="/AddContractlegacy"
                    element={
                      <PrivateRoute>
                        <Dashboard title="AddContractlegacy" />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/BrowseDocuments"
                    element={
                      <PrivateRoute>
                        <Dashboard title="BrowseDocuments" />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/SharedRepolegacy"
                    element={
                      <PrivateRoute>
                        <Dashboard title="SharedRepolegacy" />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/admins"
                    element={
                      <PrivateRoute>
                        <Dashboard title="Admins" />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/company-add"
                    element={
                      <PrivateRoute>
                        <Dashboard title="Add Company" />
                      </PrivateRoute>
                    }
                  />
                  <Route
                path="/company-edit"
                element={
                  <PrivateRoute>
                    <Dashboard title="Edit Company" />
                  </PrivateRoute>
                }
              />

              <Route
                path="/Companieslegacy"
                element={
                  <PrivateRoute>
                    <Dashboard title="Companieslegacy" />
                  </PrivateRoute>
                }
              />

              <Route
                path="/review"
                element={
                  <PrivateRoute>
                    <Dashboard title="Review" />
                  </PrivateRoute>
                }
              />

              <Route
                path="/training"
                element={
                  <PrivateRoute>
                    <Dashboard title="Training" />
                  </PrivateRoute>
                }
              />

              <Route
                path="/users"
                element={
                  <PrivateRoute>
                    <Dashboard title="Users" />
                  </PrivateRoute>
                }
              />
              <Route
                path="/add-contract"
                element={
                  <PrivateRoute>
                    <Dashboard title="Add-Contract" />
                  </PrivateRoute>
                }
              />
              <Route
                path="/notifications"
                element={
                  <PrivateRoute>
                    <Dashboard title="Notifications" />
                  </PrivateRoute>
                }
              />
              <Route
                path="/contract-add"
                element={
                  <PrivateRoute>
                    <Dashboard title="Add Contract" />
                  </PrivateRoute>
                }
              />

              <Route
                path="/contract-edit"
                element={
                  <PrivateRoute>
                    <Dashboard title="Edit Contract" />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admins"
                element={
                  <PrivateRoute>
                    <Dashboard title="Admins" />
                  </PrivateRoute>
                }
              />  
              <Route
                path="/reviewerslegacy"
                element={
                  <PrivateRoute>
                    <Dashboard title="Reviewerslegacy" />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin-add"
                element={
                  <PrivateRoute>
                    <Dashboard title="Add Company" />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin-edit"
                element={
                  <PrivateRoute>
                    <Dashboard title="Edit Company" />
                  </PrivateRoute>
                }
              />

              <Route
                path="/user-add"
                element={
                  <PrivateRoute>
                    <Dashboard title="Add Company" />
                  </PrivateRoute>
                }
              />
              <Route
                path="/user-edit"
                element={
                  <PrivateRoute>
                    <Dashboard title="Edit Company" />
                  </PrivateRoute>
                }
              />

              <Route
                path="/reviewer-add"
                element={
                  <PrivateRoute>
                    <Dashboard title="Add Company" />
                  </PrivateRoute>
                }
              />

              <Route
                path="/reviewer-edit"
                element={
                  <PrivateRoute>
                    <Dashboard title="Edit Company" />
                  </PrivateRoute>
                }
              />
              <Route
                path="/sso-setting"
                element={
                  <PrivateRoute>
                    <Dashboard title="SSO Setting" />
                  </PrivateRoute>
                }
              />

              <Route
                path="/licencing"
                element={
                  <PrivateRoute>
                    <Dashboard title="Licencing" />
                  </PrivateRoute>
                }
              />

                  

                  <Route path='*' element={<NotFoundComponent />} />
                </Routes>
          
              </PermissionProvider>
            
          </FormProvider>
          </DocumentProvider>
        </AppProvider>
      </AuthProvider>
    </Router>
  );
};

export default index;
