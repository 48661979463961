import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./chatai.css";
import useAxios from "../../hooks/useAxios";
import LoadingScreen from "../loader/LoadingScreen";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { resolve } from "../../api/resolve";
import sendIcon from "../../assets/icons/send_white.png";
import copy from "../../assets/icons/copy-clause.png";

const DocChatAI = React.forwardRef((props, ref) => {
  const { documentData, textareaRef } = props;
  const [searchText, setSearchText] = useState("");
  const [conversationHistory, setConversationHistory] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const { t } = useTranslation();
  const baseURL = process.env.REACT_APP_BACKEND_BASE_URL;
  const [templateList, setTemplateList] = useState([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState("");
  const [clauseList, setClauseList] = useState([]);
  const [selectedClauseId, setSelectedClauseId] = useState("");

  const chatContainerRef = useRef(null);
  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(false);
  const api = useAxios();

  useEffect(() => {
    if (shouldScrollToBottom && chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
      setShouldScrollToBottom(false);
    }
  }, [conversationHistory, shouldScrollToBottom]);

  function htmlToPlainText(html) {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
  }
  const getTemplateListApi = async () => {
    const type = "template";
    const responceData = await resolve(
      api
        .get(`${baseURL}/contract/template/list/?type=${type}`)
        .then((res) => res.data)
    );
    if (responceData["data"]) {
      setTemplateList(responceData["data"].data);
    }
  };

  const templateOptions = templateList.map((template) => ({
    value: template.id,
    label: template.title,
  }));

  const getClauseListApi = async () => {
    try {
      const responseData = await resolve(
        api.get(`${baseURL}/contract/list/clause/`).then((res) => res.data)
      );
      if (responseData["data"] && responseData["data"].results) {
        const updatedList = responseData["data"].results.map((item) => {
          let clauseTextArray;
          try {
            clauseTextArray = JSON.parse(item.clause_text.replace(/'/g, '"'));
          } catch (error) {
            console.error("Error parsing clause_text:", error);
            clauseTextArray = [];
          }
          return { ...item, clause_text: clauseTextArray };
        });
        setClauseList(updatedList);
      }
    } catch (error) {
      console.error("Error fetching clause library list:", error);
    }
  };

  const clauseOptions = clauseList.map((clause) => ({
    value: clause.id,
    label: clause.clause_name,
  }));

  const handleSearch = async () => {
    try {
      setLoading(true);
      if (searchText === "" || documentData === "") {
        toast.error("Please provide context");
      } else {
        addMessageToHistory();
        const payload = {
          searchText,
          aiContent: htmlToPlainText(documentData),
          conversationHistory,
          template_id: selectedTemplateId,
          clause_id: selectedClauseId,
        };

        const response = await axios.post(
          `${baseURL}/contract/generatedocchat/`,
          payload
        );

        if (response.status === 200) {
          const recommendations = response.data;
          const recommendationsArray = Object.values(recommendations);

          setConversationHistory((prevHistory) => {
            const updatedHistory = [...prevHistory];
            updatedHistory[updatedHistory.length - 1] = {
              ...updatedHistory[updatedHistory.length - 1],
              assistant: recommendationsArray,
            };
            return updatedHistory;
          });
        } else {
          throw new Error("Failed to fetch recommendations");
        }
      }
    } catch (error) {
      console.error("Error fetching recommendations:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleEnterPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  useEffect(() => {
    getTemplateListApi();
    getClauseListApi();
  }, []);

  const addMessageToHistory = () => {
    const userMessage = { user: searchText, assistant: [] };
    setConversationHistory((prevHistory) => [...prevHistory, userMessage]);
    setSearchText("");
    setShouldScrollToBottom(true);
  };

  const handleClearChat = () => {
    setConversationHistory([]); // Clear the chat history
  };

  const handleCopyClick = (textToCopy) => {
    try {
      const textarea = document.createElement("textarea");
      textarea.value = textToCopy;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      toast.success("Copied to clipboard");
    } catch (error) {
      toast.error("Failed to copy to clipboard");
    }
  };

  return (
    <div className="chat-container">
      <div className="chat-header">
        <>
          {conversationHistory.length > 0 && !isLoading && (
            <button
              onClick={handleClearChat}
              className="clear-button"
              data-tip="clear chat history"
            >
              {t("Clear")}
            </button>
          )}
          <ReactTooltip backgroundColor="#400835" textColor="#ffffff" />
        </>
      </div>
      <div
        className={`chat-history ${
          conversationHistory.length === 0 ? "no-overflow" : ""
        }`}
        ref={chatContainerRef}
      >
        {conversationHistory.length === 0 ? (
          <div className="no-history-message">
            <h2>{t("Start AI chat with Document")}</h2>
          </div>
        ) : (
          conversationHistory.map((entry, index) => (
            <div key={index} className="chat-message">
              <div className="user-message">
                <div className="message-content">
                  <strong>You:</strong> {entry.user}
                </div>
              </div>
              {entry.assistant.map((response, idx) => (
                <div key={idx} className="assistant-message">
                  <div className="message-content">
                    <strong>AI:</strong> {response}
                    <button
                      onClick={() => handleCopyClick(response)}
                      className="copy-button"
                    >
                      <img
                        src={copy}
                        alt="Copy Icon"
                        className="copy-icon"
                        style={{
                          width: "25px",
                          height: "25px",
                        }}
                      />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          ))
        )}
        {isLoading && <LoadingScreen />}
      </div>
      <div className="chat-input">
        {/* First line for the dropdown */}
        <div className="input-line">
          <Select
            id="template-dropdown"
            name="template-dropdown"
            className="border-2 border-primary rounded-lg outline-none"
            value={templateOptions.find(
              (option) => option.value === selectedTemplateId
            )}
            onChange={(option) => {
              setSelectedTemplateId(option ? option.value : "");
            }}
            options={templateOptions}
            placeholder="Templates"
            isClearable
            menuPlacement="top"
            styles={{
              container: (provided) => ({
                ...provided,
                width: "50%",
                borderRadius: "5px",
              }),
              control: (provided) => ({
                ...provided,
                border: "none",
              }),
              menu: (provided) => ({
                ...provided,
                marginTop: "0",
                marginBottom: "5px",
              }),
              menuPortal: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
            }}
            menuPortalTarget={document.body}
          />
          <Select
            id="template-dropdown"
            name="template-dropdown"
            className="border-2 border-primary rounded-lg outline-none"
            value={clauseOptions.find(
              (option) => option.value === selectedClauseId
            )}
            onChange={(option) => {
              setSelectedClauseId(option ? option.value : "");
            }}
            options={clauseOptions}
            placeholder="Clauses"
            isClearable
            menuPlacement="top"
            styles={{
              container: (provided) => ({
                ...provided,
                width: "50%",
                borderRadius: "5px",
              }),
              control: (provided) => ({
                ...provided,
                border: "none",
              }),
              menu: (provided) => ({
                ...provided,
                marginTop: "0",
                marginBottom: "5px",
              }),
              menuPortal: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
            }}
            menuPortalTarget={document.body}
          />
        </div>

        {/* Second line for the input and button */}
        <div className="input-line">
          <input
            type="text"
            placeholder={t("Ask a question ...")}
            value={searchText}
            onChange={handleSearchChange}
            onKeyPress={handleEnterPress}
            className="chat-text-input"
          />
          <button
            onClick={() => {
              handleSearch();
            }}
            className="chat-send-button"
          >
            <img src={sendIcon} alt="Send Icon" className="send-icon" />
          </button>
        </div>
      </div>
    </div>
  );
});

export default DocChatAI;
