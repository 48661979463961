import React, { useRef, useState } from "react";
import upload_documents from "../../assets/icons/upload_documents.png";
import "./search.css";
const FileUploadPopup = ({
  isOpen,
  onClose,
  onUpload,
  onCancel,
  t,
  uploadDocuments,
}) => {
  const inputRef = useRef(null);
  const [fileName, setFileName] = useState("");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
    }
  };

  const handleUploadClick = () => {
    if (inputRef.current.files.length > 0) {
      onUpload(inputRef.current.files[0]);
    }
  };

  if (!isOpen) return null; // If not open, don't render anything

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        {/* Close Button */}
        <button className="modal-close-btn" onClick={onClose}>
          <h1>X</h1>
        </button>
        <br />

        {/* File Upload Content */}
        <div className="file-upload-content">
          <div
            onClick={() => inputRef.current.click()}
            className="upload-area p-5 border-2 border-primary text-primary rounded-lg text-center mt-2 mb-1"
          >
            <img
              style={{ display: "inline" }}
              src={upload_documents}
              width={50}
              height={50}
              alt="Upload"
            />
            <h4 className="font-bold text-xl mt-3">
              {t("Upload docx document for editing")}
            </h4>
          </div>
          <input
            type="file"
            accept=".doc, .docx"
            hidden
            ref={inputRef}
            onChange={handleFileChange}
          />
          {fileName && (
            <div className="file-info mt-2">
              <p>Selected file: {fileName}</p>
            </div>
          )}
          <div className="button-group mt-3">
            <button onClick={handleUploadClick} className="btn btn-upload">
              {t("Upload")}
            </button>
            {/* <p className="disclaimer">
              {t(
                "allModule.Disclaimer Please have the content of the document verified by a legal professional at all times"
              )}
            </p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileUploadPopup;
