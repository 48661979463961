import React from "react";
import Select from "react-select";
import "./search.css";

const SearchPopup = ({
  isOpen,
  onClose,
  handleSearch,
  search,
  setSearch,
  templateOptions,
  selectedTemplateId,
  setSelectedTemplateId,
  t,
  docchat,
  setDocchat,
  aiPurple,
  aiOrange,
}) => {
  if (!isOpen) return null; // If not open, don't render anything

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        {/* Close Button */}
        <button className="modal-close-btn" onClick={onClose}>
          X
        </button>

        {/* Popup Content */}
        <div className="popup-content">
          <div className="form-group">
            <label>
              {t(
                "Enter the name of the contract you wish to create along with its description"
              )}
            </label>
            <textarea
              value={search}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  handleSearch();
                }
              }}
              onChange={(e) => setSearch(e.target.value)}
              className="input-field "
              style={{
                minHeight: "3.3em",
                width: "100%",
                resize: "none",
              }}
            />
          </div>

          <div className="form-group">
            <label>
              {t(
                "Alternatively, select a template from the options below to generate the contract"
              )}
            </label>
            <Select
              id="template-dropdown"
              name="template-dropdown"
              className="input-field"
              value={templateOptions.find(
                (option) => option.value === selectedTemplateId
              )}
              onChange={(option) => {
                setSelectedTemplateId(option ? option.value : "");
              }}
              options={templateOptions}
              placeholder={t("Select a Template...")}
              isClearable
              styles={{
                container: (provided) => ({
                  ...provided,
                  width: "100%",
                  borderRadius: "20px",
                  border: "none",
                }),
                control: (provided) => ({
                  ...provided,
                  width: "100%",
                  border: "1px solid #ccc",
                }),
              }}
            />
          </div>

          <div className="form-group">
            <button onClick={handleSearch} className=" mt-3 btn btn-theme">
              {t("Generate")}
            </button>
          </div>

          {/* <p className="disclaimer">
            {t(
              "allModule.Disclaimer Please have the content of the document verified by a legal professional at all times"
            )}
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default SearchPopup;
