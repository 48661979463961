import React, { useState, useContext, useRef, useEffect } from "react";
import LoadingScreen from "../loader/LoadingScreen";
import { useTable } from "react-table";
import "./contracts.css";
import DownArrow from "../../assets/icons/down-arrow.svg";
import ReactTooltip from "react-tooltip";
import { saveAs } from "file-saver";
import { customAlphabet } from "nanoid";
import EditIcon from "../../assets/icons/Edit.svg";
import infoicon from "../../assets/icons/infoicon.svg";
import DeleteIcon from "../../assets/icons/Delete.svg";
import moveIcon from "../../assets/icons/send.png";
import playritesrd from "../../assets/icons/playritesrd.png";
import clauseicon from "../../assets/icons/aireviewtools.svg";
import TrashIcon from "../../assets/icons/trash.png";
import Selection from "../../assets/icons/selection.png";
import FormContext from "../../context/FormContext";
import AppContext from "../../context/AppContext";
import axios from "axios";
import AuthContext from "../../context/AuthContext";
import MoveRepoModal from "../modals/MoveRepoModal";
import MoveRepoModallegacy from "../modallegacy/MoveRepoModallegacy";
import PlayriteDiligenceModal from "../Playrite/PlayriteDiligenceModal";
import { docStatus } from "../../constants/constants";
import PlayriteDiligenceLegacy from "../Playrite/PlayriteDiligenceLegacy";
import useAxios from "../../hooks/useAxios";
import { toast } from "react-toastify";
import { resolve } from "../../api/resolve";
import DeleteModal from "../modals/DeleteModal";
import { useTranslation } from "react-i18next";
import FormContextlegacy from "../../contextlegacy/FormContextlegacy";
import DownloadIcon from "../../assets/icons/Download1srd.svg";
import ReportIcon from "../../assets/icons/Report.svg";
import Bell from "../../assets/icons/Bell.svg";
import ReportListModal from "../modals/ReportListModal";

export const NoDataComponent = ({ colLength }) => {
  const [rows] = useState(Array(4).fill(""));
  const [cols] = useState(Array(colLength).fill(""));
  return (
    <>
      {rows.map(() => (
        <tr>
          {cols.map(() => (
            <td className="px-5 py-2 font-medium text-smW">--</td>
          ))}
        </tr>
      ))}
    </>
  );
};

// shubhay changes start:-
// const ContractsTable = ({
const ContractsTablelegacy = ({
  // shubhay changes end
  contractsData,
  contractTableColumns,
  prevPage,
  nextPage,
  currentPage,
  setCurrentPage,
  selectedContract,
  setSelectedContract,
  sortingOrder,
  setSortingOrder,
  setSortColumn,
  setIsRemainderOpen,
  setIsDeleteModalOpen,
  pageLimit,
  setPageLimit,
  contractCount,
  setContractCount,
  showAdvanceSearchData,
  setShowAdvanceSearchData,
  getContracts,
  setIsRenewalAbsent,
  showDrawer,
  setPageLoading,
  setAdvanceSearchText,
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns: contractTableColumns, data: contractsData });
  const { getSasToken, sasToken, highlightToken, getHighlightSasToken } =
    useContext(AppContext);
  const api = useAxios();
  const { t } = useTranslation();
  const { setEditFormData, setIsEditing } = useContext(FormContext);
  const [currentRow, setCurrentRow] = useState(0);
  const { setActiveLink } = useContext(AppContext);
  const { user } = useContext(AuthContext);
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const [base64Data, setBase64Data] = useState(null);
  const [isMultiSelect, setIsMultiSelect] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isplayriteModalOpen, setisplayriteModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [sectionTaskList, setSectionTaskList] = useState([]);
  const inputRef = useRef();
  const nanoid = customAlphabet("1234567890", 18);
  const [reviewDocList, setReviewDocList] = useState([]);
  const [reviewDocSearchText, setReviewDocSearchText] = useState("");
  const [reviewDocCurrentPage, setReviewDocCurrentPage] = useState(1);
  const [reviewDocPageLimit, setReviewDocPageLimit] = useState(10);
  const [reviewDocNextBtnLimit, setReviewDocNextBtnLimit] = useState(0);
  const [reviewDocCount, setReviewDocCount] = useState(0);
  const [errMsgReviewDoc, setErrMsgReviewDoc] = useState("");
  const [sortingOrderReviewDoc, setSortingOrderReviewDoc] = useState("desc");
  const [sortColumnReviewDoc, setSortColumnReviewDoc] = useState("id");
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState([]);
  const { getNotifications } = useContext(FormContext);
  const [repos, setRepos] = useState([]);
  const [playBookList, setplayBookList] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [reportIds, setReportIds] = useState([]);
  const [selecteddocs, setSelecteddocs] = useState([]);
  const [countryListData, setCountryListData] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [reportLists, setReportLists] = useState([]);
  const [isReportsModalOpen, setIsReportsModalOpen] = useState(false);
  const [docName, setDocName] = useState("");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleReports = async (id, clearText) => {
    try {
      const response = await api.get(
        `contract/document/list/?search=${
          clearText ? "" : reviewDocSearchText
        }&ordering=${sortColumnReviewDoc}&sortingOrder=${sortingOrderReviewDoc}&page=${reviewDocCurrentPage}&limit=${reviewDocPageLimit}&review_doc=1&legacy_doc_id=${id}`
      );

      const data = response.data;

      if (data) {
        setReportLists(data.results);
        setIsReportsModalOpen(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getdoclist = async () => {
    setisplayriteModalOpen(true);
    getSectionList();
    getPlayBookList();
  };
  const getPlayBookList = async () => {
    const res = await resolve(
      api
        .get(`contract/document/list/?type=playbook&limit=100`)
        .then((res) => res.data)
    );
    if (res.data) {
      setplayBookList(res.data.results);
    }
  };

  const getReviewDocList = async (clearText) => {
    const res = await resolve(
      api
        .get(
          `contract/document/list/?type=2&search=${reviewDocSearchText}&ordering=${sortColumnReviewDoc}&sortingOrder=${sortingOrderReviewDoc}&page=${reviewDocCurrentPage}&limit=${reviewDocPageLimit}&review_doc=1`
        )
        .then((res) => res.data)
    );
    if (res.data) {
      const { data } = res;
      const { results, count } = data;
      const filterData = [];
      if (results.length > 0) {
        setReviewDocCount(count);
        results.forEach((result) => {
          const reviewDocObj = {
            id: result.id,
            name: result.name,
            type: result.type,
            uniqueId: result.tags,
            taskdecpt: result.taskdecpt,
            json_data: {
              section: "",
              task: "",
            },
            status: docStatus[result.status],
          };
          if (result.json_data) {
            let jsonInfo = JSON.parse(result.json_data);
            let keyList = Object.keys(jsonInfo);
            if (keyList.length > 0) {
              reviewDocObj["json_data"] = {
                section: keyList[0],
                task: jsonInfo[keyList[0]][0],
              };
            }
          }
          filterData.push(reviewDocObj);
        });
      } else {
        if (reviewDocCurrentPage === 1)
          setErrMsgReviewDoc(t("home.No document added yet"));
        else setErrMsgReviewDoc(t("home.No more document to view"));
      }
      setReviewDocList([...filterData]);
      setReviewDocNextBtnLimit(Math.ceil(data.count / reviewDocPageLimit));
    } else {
      setReviewDocNextBtnLimit(0);
    }
  };
  // const checkDocumentStatus = (documentId) => {
  //   const sse = new EventSource(
  //     `${process.env.REACT_APP_BACKEND_BASE_URL}/contract/docstatus_stream/${documentId}/diligence/`
  //   );
  //   function getRealtimeData(data) {
  //     getReviewDocList();
  //     sse.close();
  //   }
  //   sse.onmessage = (e) => getRealtimeData(e.data);
  // };

  const checkDocumentStatus = async (documentIds) => {
    console.log("documentIds", documentIds);

    // Create a function to check the status of a single document
    const checkSingleDocumentStatus = async (documentId) => {
      const url = `contract/docstatus_stream/${documentId}/diligence/`;
      const responceData = await resolve(api.get(url).then((res) => res.data));
      if (responceData["data"] && responceData["data"]["count"] == 0) {
        // If the document status is not ready, recursively check after 5 seconds
        setTimeout(() => {
          checkSingleDocumentStatus(documentId);
        }, 5000);
      } else {
        // If the document status is ready, trigger the review document list update
        getReviewDocList();
      }
    };

    // Iterate over the array of document IDs and check the status for each one
    await Promise.all(documentIds.map((id) => checkSingleDocumentStatus(id)));
  };

  const getSectionTaskList = async (sectionId) => {
    setSectionTaskList([]);
    const res = await resolve(
      api
        .get(
          `nda_report/diligence/task/list/?section_id=${sectionId}&ordering=description&sortingOrder=asc`
        )
        .then((res) => res.data)
    );
    if (res.data) {
      const { data } = res;
      const { results, count } = data;
      setSectionTaskList(results);
    } else {
      setSectionTaskList([]);
    }
  };

  const uploadQuoqoDeligence = async (postData, file) => {
    setLoading(true);
    try {
      let taskId = postData.selectedPerspective;
      let taskInfo = sectionTaskList.find((task) => task.id == taskId);
      let dataInfo = {};
      if (taskInfo) {
        dataInfo[taskInfo["section_id"].section_name] = [taskInfo.task_name];
      }

      let formData = new FormData();
      // file.forEach((file) => {
      //   if (postData.aitools == "Playrite") {
      //     formData.append("file", file.id);
      //   }
      //   formData.append("legacy_doc_id", file.id);
      //   if (postData.aitools == "Playrite") {
      //     formData.append("file", file.id);
      //   }
      // }
      file.forEach((file) => {
        if (postData.aitools == "Playrite") {
          formData.append("file", file.id);
        }
        formData.append("legacy_doc_id", file.id);
      });
      formData.append("sections", JSON.stringify(dataInfo));
      formData.append("playbookId", postData.playbookId);
      formData.append("taskdecpt", postData.taskDescription);
      formData.append("perspective", postData.perspective);
      formData.append("governing_law", postData.governing_law);
      formData.append("customprompt", postData.customePrompt);

      formData.append("super_prompt", postData.superPrompt);
      const uniqueId = nanoid();
      formData.append("userEmailAddress", user.user_email);
      formData.append("uniqueID", uniqueId);
      formData.append(
        "receiveReportWebhookURL",
        "https://www.example.com/receive_report_webhook/uygjhghbygjhvbh"
      );
      setisplayriteModalOpen(false);
      let main_result = { status: 400 };
      let formDatafornda = new FormData();
      if (postData.aitools === "NDAReview") {
        try {
          // const uniqueId = nanoid();
          // formDatafornda.append("documentName", file.doc_name);
          // formDatafornda.append("userEmailAddress", user.user_email);
          // formDatafornda.append("uniqueID", uniqueId);
          // formDatafornda.append(
          //   "receiveReportWebhookURL",
          //   "https://www.example.com/receive_report_webhook/uygjhghbygjhvbh"
          // );
          // formDatafornda.append("document", base64Data);
          // file.forEach(async (file, index) => {
          //   try {
          //     let authTokens = localStorage.getItem("authTokens")
          //       ? JSON.parse(localStorage.getItem("authTokens"))
          //       : null;

          //     const apiResponse = await api.get(
          //       file.converted_file_url + "?" + highlightToken.sas_token,
          //       { responseType: "blob" }
          //     );

          //     const blob = apiResponse.data;
          //     const reader = new FileReader();

          //     reader.readAsDataURL(blob);

          //     reader.onload = function () {
          //       const formDatafornda = new FormData();
          //       const uniqueId = nanoid();
          //       const base64Data = reader.result.split("base64,")[1];
          //       formDatafornda.append("legacy_doc_id", file.id);
          //       formDatafornda.append("documentName", file.doc_name);
          //       formDatafornda.append("userEmailAddress", user.user_email);
          //       formDatafornda.append("uniqueID", uniqueId);
          //       formDatafornda.append(
          //         "receiveReportWebhookURL",
          //         "https://www.example.com/receive_report_webhook/uygjhghbygjhvbh"
          //       );
          //       formDatafornda.append("document", base64Data);

          const apiResponse = api
            .post(
              `${process.env.REACT_APP_BACKEND_BASE_URL}/nda_report/receive_nda_doc_webhook`,
              formData,
              {
                "Content-Type": "application/json",
              }
            )
            .then((res) => {
              if (res.status === 200) {
                setTimeout(() => {
                  getNotifications();
                  getReviewDocList();
                  checkDocumentStatus(uniqueId);
                }, 4000);

                main_result = res;
                toast.success(t("allModule.Document uploaded successfully"));
              }
            });

          //     reader.onerror = function (error) {
          //       console.error("Error reading the file:", error);
          //       toast.error("Error reading the document");
          //     };
          //   } catch (error) {
          //     console.error("Error in inner try block:", error);
          //     toast.error("Error processing document");
          //   }
          // });
        } catch (error) {
          console.error("Error in outer try block:", error);
          toast.error("Error processing document");
        }
      } else if (postData.aitools == "ContractReview") {
        const res = await api.post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/nda_report/diligence/upload/`,
          formData
        );
        main_result = res;
      } else if (postData.aitools == "TransactionReview") {
        const res = await api.post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/nda_report/transaction/upload/`,
          formData
        );
        main_result = res;
      } else if (postData.aitools == "IPReview") {
        const res = await api.post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/nda_report/ipreview/upload/`,
          formData
        );
        main_result = res;
      } else if (postData.aitools == "SectoralReview") {
        const res = await api.post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/nda_report/Sectoralreview/upload/`,
          formData
        );
        main_result = res;
      } else if (postData.aitools == "Playrite") {
        const res = await api.post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/nda_report/playrite/deviation/uploadlegacy/`,
          formData
        );
        main_result = res;
      } else {
        let res = { status: 400 };
      }
      // if "NDAReview""ContractReview""TransactionReview""IPReview">"SectoralReview""Playrite"

      if (
        main_result.status == 200 ||
        main_result.message == "file uploaded successfully"
      ) {
        toast.success(
          t(
            "allModule.Document uploaded successfully, Click the highlighted AI Review icon to view the report"
          )
        );
        setReportIds(selectedIds);
        getReviewDocList();
        setTimeout(() => {
          getNotifications();
        }, 4000);
        checkDocumentStatus(main_result.data.doc_id);
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(
          t("allModule.Something went wrong, please try again later.")
        );
      }
    }
    setSelectedIds([]);
    setSelecteddocs([]);
    setIsMultiSelect(!isMultiSelect);
    setLoading(false);
  };

  const getSectionList = async (clearText) => {
    let seccat = "";
    if (clearText == "NDAReview") {
      seccat = "";
    } else if (clearText == "ContractReview") {
      seccat = "";
    } else if (clearText == "TransactionReview") {
      seccat = "";
    } else if (clearText == "IPReview") {
      seccat = "";
    } else if (clearText == "SectoralReview") {
      seccat = "";
    } else if (clearText == "Playrite") {
      seccat = "Playbook";
    }
    const res = await resolve(
      api
        .get(
          `nda_report/diligence/section/list/?SecCat=${seccat}&ordering=section_name&sortingOrder=asc`
        )
        .then((res) => res.data)
    );
    if (res.data) {
      const { data } = res;
      const { results, count } = data;
      setSectionList(results);
    }
  };

  const getCountryListApi = async () => {
    const responceData = await resolve(
      api.get("/company/countries/list/").then((res) => res.data)
    );
    if (responceData["data"]) {
      setCountryListData(responceData["data"]);
    }
  };

  const getRepos = async () => {
    const res = await resolve(
      api.get(`legacy_contract/get_all_repos/`).then((res) => res.data)
    );
    if (res.data) {
      const filterData = [];
      if (res.data && res.data.length > 0) {
        res.data.forEach((result) => {
          const contractObj = {
            repo_name: result.repo_name,
            id: result.id,
          };
          filterData.push(contractObj);
        });
      }
      setRepos([...filterData]);
    }
  };

  const downloadHighlightedDoc = async () => {
    try {
      const res = await api.post(
        "/legacy_contract/download_list_contract/",
        {},
        {
          responseType: "blob", // Set the response type to blob
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // Check if response is successful
      if (res.status === 200) {
        const blob = new Blob([res.data]);
        const filename = "DocumentList.xlsx";

        // Use FileSaver.js to trigger download
        saveAs(blob, filename);
      } else {
        console.error("Failed to download file:", res.statusText);
        // Handle error
      }
    } catch (error) {
      console.error("Error downloading file:", error);
      // Handle error
    }
  };

  const deleteContracts = async () => {
    setPageLoading(true);
    const res = await resolve(
      api
        .post(
          `legacy_contract/delete_multiple_contracts/`,
          { id_list: selectedIds },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => res.data)
    );
    if (res.data) {
      if (res.data.success) {
        getContracts();
      }
    }
    setDeleteModalOpen(false);
    setPageLoading(false);
  };

  useEffect(() => {
    getSectionList();
    getCountryListApi();
    getPlayBookList();
  }, []);

  useEffect(() => {
    getRepos();
  }, []);
  return (
    <>
      {contractsData.length > 0 && (
        <div className="mt-3 flex justify-between items-center">
          <div className="flex items-center">
            {contractCount > 5 && (
              <>
                <span>Show</span>
                <select
                  className="border-2 border-primary outline-none mx-1 rounded-md cursor-pointer"
                  style={{ maxWidth: "45px", minWidth: "fit-content" }}
                  onChange={(e) => setPageLimit(e.target.value)}
                >
                  <option selected>5</option>
                  {contractCount >= 10 && <option>10</option>}
                  {contractCount >= 15 && <option>15</option>}
                  {contractCount >= 25 && <option>25</option>}
                </select>
                <span>{t("common.Results")}</span>
              </>
            )}
            {showAdvanceSearchData && (
              <button
                className="ml-3 w-14 bg-primary rounded-lg text-white text-sm space-x-1"
                onClick={() => {
                  setAdvanceSearchText("");
                  setShowAdvanceSearchData(false);
                  getContracts();
                }}
              >
                <b>X</b> {t("common.Clear")}
              </button>
            )}
          </div>
          <div className="flex items-center">
            <img
              src={DownloadIcon}
              style={{
                width: "25px",
                height: "25px",
                cursor: "pointer",
                marginRight: "20px",
              }}
              onClick={() => {
                downloadHighlightedDoc();
              }}
              data-tip={t("allModule.Download Document List")}
            />
            <div
              className="flex text-primary border-t-2 border-b-2 border-primary rounded-lg"
              style={{ height: "35px" }}
            >
              <button
                className={`btn-sm border-r-2 border-l-2 border-primary font-semibold rounded-tl-lg rounded-bl-lg ${
                  !prevPage && "bg-inputBackground"
                }`}
                disabled={!prevPage}
                onClick={() => setCurrentPage(currentPage - 1)}
              >
                {t("common.Prev")}
              </button>
              <span className="btn-sm font-semibold">{currentPage}</span>
              <button
                className={`btn-sm border-l-2 border-r-2 border-primary font-semibold rounded-tr-lg rounded-br-lg ${
                  !nextPage && "bg-inputBackground"
                }`}
                disabled={!nextPage}
                onClick={() => setCurrentPage(currentPage + 1)}
              >
                {t("common.Next")}
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="mt-2 flex table-container mb-5 search_flex">
        {/* {isDrawerOpen && showDrawer && ( */}
        <div
          className="flex flex-col justify-around items-center"
          style={{
            border: "1px solid #400835",
            borderRight: "none",
            borderTopLeftRadius: "10px",
            borderBottomLeftRadius: "10px",

            height: "fit-content",
            padding: "5px 5px",
            alignContent: "center",
          }}
        >
          <img
            src={Selection}
            style={{
              width: "20px",
              height: "20px !important",
              cursor: "pointer",
              margin: "2px 2px 5px 2px",
              paddingLeft: "2px",
              marginBottom: "10px",
              alignContent: "center",
            }}
            onClick={() => {
              setSelectedIds([]);
              setSelecteddocs([]);
              setIsMultiSelect(!isMultiSelect);
            }}
            data-tip={t("allModule.Select Contracts")}
          />
          <ReactTooltip
            backgroundColor="#400835"
            textColor="#ffffff"
            className="max-w-lg break-words"
          />

          <>
            {/* {user.user_type === "admin" && (
              <>
                <img
                  src={TrashIcon}
                  style={{
                    width: "20px",
                    height: "20px !important",
                    cursor: "pointer",
                    marginBottom: "10px",
                  }}
                  data-tip={t("allModule.Delete Contracts")}
                  onClick={() => {
                    if (selectedIds.length === 0) {
                      toast.error("Please select documents to delete");
                    } else {
                      setDeleteModalOpen(true);
                    }
                  }}
                />

                <ReactTooltip
                  backgroundColor="#400835"
                  textColor="#ffffff"
                  className="max-w-lg break-words"
                />
              </>
            )} */}

            <img
              src={clauseicon}
              style={{
                width: "20px",
                height: "20px !important",
                cursor: "pointer",
                marginBottom: "10px",
              }}
              data-tip={t("allModule.AI Reviews")}
              onClick={() => {
                if (selectedIds.length != 0 || selecteddocs.length != 0) {
                  getdoclist();
                } else {
                  toast.error("Please select documents to perform AI review");
                }
              }}
            />
            <ReactTooltip
              backgroundColor="#400835"
              textColor="#ffffff"
              className="max-w-lg break-words"
            />
          </>

          {/* <span
              className="text-sm font-bold  bg-primary text-white items-center justify-center"
              onClick={() => {
                setIsMultiSelect(false);
                setIsDrawerOpen(true);
                setSelectedIds([]);
                setSelecteddocs([]);
              }}
              style={{
                display: "flex",
                cursor: "pointer",
                border: "1px solid #400835",
                borderRadius: "100%",
                height: 16,
                width: 16,
                marginBottom: "5px",
              }}
              data-tip="Close"
            >
              {"X"}
            </span> */}
          <ReactTooltip
            backgroundColor="#400835"
            textColor="#ffffff"
            className="max-w-lg break-words"
          />
        </div>
        {/* )} */}
        {!isDrawerOpen && showDrawer && (
          <>
            <span
              className="text-lg font-semibold bg-primary text-white"
              onClick={() => setIsDrawerOpen(true)}
              style={{
                borderTopLeftRadius: "5px",
                borderBottomLeftRadius: "5px",
                height: 30,
                display: "grid",
                placeItems: "center",
                cursor: "pointer",
              }}
              data-tip="Open"
            >
              {"<"}
            </span>
            <ReactTooltip
              backgroundColor="#400835"
              textColor="#ffffff"
              className="max-w-lg break-words"
            />
          </>
        )}

        <div
          className="shadow border-2 border-primary  sm:rounded-lg max-h-96 overflow-y-auto tab_resppp"
          style={{ width: "100%", borderTopLeftRadius: 0 }}
        >
          <table {...getTableProps()} className="w-full ">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    // Add the sorting props to control sorting. For this example
                    // we can add them into the header props
                    <th
                      scope="col"
                      className="text-left px-2 pt-2"
                      {...column.getHeaderProps()}
                      // style={{ maxWidth: "10px" }}
                    >
                      <div className="flex items-center">
                        <span>{t("constant." + column.render("Header"))}</span>
                        {/* Add a sort direction indicator */}
                        {sortingOrder === "desc" ? (
                          <span
                            onClick={(e) => {
                              setSortingOrder("asc");
                              setSortColumn(column.id);
                            }}
                            style={{ cursor: "pointer", marginTop: 2 }}
                            className="ml-1"
                          >
                            {/* &#11165; */}
                            {column.render("Header") != "Created By" && (
                              <img src={DownArrow} />
                            )}
                          </span>
                        ) : (
                          <span
                            onClick={(e) => {
                              setSortingOrder("desc");
                              setSortColumn(`-${column.id}`);
                            }}
                            style={{
                              cursor: "pointer",
                              transform: "rotate(180deg)",
                            }}
                            className="ml-1"
                          >
                            <img src={DownArrow} />
                          </span>
                        )}
                      </div>
                    </th>
                  ))}
                  <th
                    scope="col"
                    className="text-left px-6 pt-2"
                    style={{ width: "500px" }}
                  >
                    {t("common.Action")}
                  </th>
                  {/* <th scope="col" className="text-left px-6 pt-2">
                    {t("allModule.Notifications")}
                  </th> */}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {contractsData.length < 1 && (
                <NoDataComponent colLength={contractTableColumns.length} />
              )}
              {contractsData.length > 0 &&
                rows.map((row, i) => {
                  // new
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps()}
                      onClick={() => {
                        setCurrentRow(i);
                        setSelectedContract({
                          ...selectedContract,
                          doc_name: row.original.doc_name,
                          file_url: row.original.file_url,
                          converted_file_url: row.original.converted_file_url,
                          id: row.original.id,
                        });
                      }}
                      className={`cursor-pointer ${
                        currentRow === i && "bg-[#F6C107]"
                      }`}
                      style={{ position: "relative" }}
                    >
                      {isMultiSelect ? (
                        <input
                          type="checkbox"
                          style={{
                            position: "absolute",
                            top: "30%",
                            left: "2px",
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (e.target.checked) {
                              setSelectedIds([...selectedIds, row.original.id]);
                              setSelecteddocs([...selecteddocs, row.original]);
                            } else {
                              let filterData = selectedIds.filter(
                                (item) => item !== row.original.id
                              );
                              setSelectedIds([...filterData]);
                              let filterData2 = selecteddocs.filter(
                                (item) => item.id !== row.original.id
                              );
                              setSelecteddocs([...filterData2]);
                            }
                          }}
                          checked={selectedIds.includes(row.original.id)}
                        />
                      ) : isMultiSelect &&
                        row.original.uploaded_by === user.user_id ? (
                        <input
                          type="checkbox"
                          style={{
                            position: "absolute",
                            top: "30%",
                            left: "2px",
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            if (e.target.checked) {
                              e.stopPropagation();
                              setSelectedIds([...selectedIds, row.original.id]);
                              setSelecteddocs([...selecteddocs, row.original]);
                            } else {
                              let filterData = selectedIds.filter(
                                (item) => item !== row.original.id
                              );
                              setSelectedIds([...filterData]);
                              let filterData2 = selecteddocs.filter(
                                (item) => item.id !== row.original.id
                              );
                              setSelecteddocs([...filterData2]);
                            }
                          }}
                          checked={selectedIds.includes(row.original.id)}
                        />
                      ) : null}
                      {row.cells.map((cell) => {
                        return (
                          <>
                            <td
                              {...cell.getCellProps()}
                              className={`px-2 py-2 font-medium text-sm contracts-col-data ${
                                isMultiSelect ? "ml-3" : ""
                              } ${
                                cell.value === "In-Active" && "text-red-500"
                              }`}
                              style={
                                cell.column.Header === "Contract Title"
                                  ? {
                                      width: screenWidth < 1000 ? 110 : 350,
                                      overflow: "hidden",
                                      display: "inline-block",
                                      textOverflow: "ellipsis",
                                    }
                                  : cell.column.Header === "Created By" ||
                                    cell.column.Header === "Created at" ||
                                    cell.column.Header === "Renewal Date" ||
                                    cell.column.Header === "Date Uploaded"
                                  ? {
                                      width: 500,
                                    }
                                  : cell.column.Header === "Contract Type"
                                  ? {
                                      width: 400,
                                    }
                                  : null
                              }
                            >
                              <span
                                data-tip={
                                  cell.column.Header === "Contract Title" &&
                                  cell.value.length > 20
                                    ? cell.value
                                    : null
                                }
                              >
                                {cell.render("Cell")}
                              </span>
                              {cell.column.Header === "Contract Title" && (
                                <ReactTooltip
                                  backgroundColor="#400835"
                                  textColor="#ffffff"
                                  className="max-w-lg break-words"
                                />
                              )}
                            </td>
                          </>
                        );
                      })}
                      <td className="pr-2 py-2">
                        {/* <button
                              className='bg-secondary text-white font-semibold p-1 pl-6 pr-6 rounded-lg'
                              onClick={() => {
                                setEditFormData(row.original);
                                setIsEditing(true);
                                setActiveLink('ContractForm');
                              }}
                            >
                              Edit
                            </button> */}
                        <span className={`flex px-1 py-1 gap-2`}>
                          <button
                            className="btn btn_theme btn-sm w-10 mt-1 mb-1 sm:mt-0 sm:mb-0"
                            data-tip={row.original.path}
                            style={{ width: "40px" }} // Ensure consistent width
                          >
                            <img src={infoicon} className="" width={20} />
                          </button>
                          <button
                            className="btn btn_theme btn-sm w-10 mt-1 mb-1 sm:mt-0 sm:mb-0"
                            data-tip={t("allModule.Edit Metadata")}
                            onClick={() => {
                              setEditFormData(row.original);
                              setIsEditing(true);
                              setActiveLink("ContractFormEdit");
                            }}
                            style={{ width: "40px" }} // Ensure consistent width
                          >
                            <img src={EditIcon} width={20} />
                          </button>
                          <button
                            className="btn btn_theme btn-sm w-10 mt-1 mb-1 sm:mt-0 sm:mb-0"
                            data-tip={t("allModule.View AI Reports")}
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              setDocName(row.original.doc_name);
                              handleReports(row.original.id);
                            }}
                            style={{
                              width: "40px",
                              backgroundColor: reportIds.includes(
                                row.original.id
                              )
                                ? "var(--text-cl)"
                                : "",
                            }}
                          >
                            <img
                              src={ReportIcon}
                              style={{
                                width: "20px",
                                height: "20px !important",
                                cursor: "pointer",
                              }}
                            />
                          </button>

                          {(row.original && row.original.reminder_days) ||
                          (row.original && row.original.reminder_date) ? (
                            <button
                              className="btn btn_theme btn-sm w-10 mt-1 mb-1 sm:mt-0 sm:mb-0"
                              onClick={() => {
                                const renewalPresent =
                                  row.original &&
                                  row.original.renewal_date === "Not Added";
                                setEditFormData(row.original);
                                setIsRemainderOpen(true);
                                setIsRenewalAbsent(renewalPresent);
                              }}
                              data-tip={
                                (row.original && row.original.reminder_days) ||
                                (row.original && row.original.reminder_date)
                                  ? t("allModule.Edit Reminder")
                                  : t("allModule.Add Reminder")
                              }
                              style={{ width: "40px" }} // Ensure consistent width
                            >
                              <img src={Bell} width={20} />
                            </button>
                          ) : (
                            <button
                              className="btn btn_theme btn-sm w-10 mt-1 mb-1 sm:mt-0 sm:mb-0"
                              onClick={() => {
                                const renewalPresent =
                                  row.original &&
                                  row.original.renewal_date === "Not Added";
                                setEditFormData(row.original);
                                setIsRemainderOpen(true);
                                setIsRenewalAbsent(renewalPresent);
                              }}
                              data-tip={
                                (row.original && row.original.reminder_days) ||
                                (row.original && row.original.reminder_date)
                                  ? t("allModule.Edit Reminder")
                                  : t("allModule.Add Reminder")
                              }
                              style={{
                                backgroundColor: "var(--text-cl)",
                                color: "white",
                                width: "40px",
                              }}
                            >
                              <img src={Bell} width={20} />
                            </button>
                          )}
                          {row.original.uploaded_by === user.user_id ? (
                            <button
                              className="btn btn_theme btn-sm w-10 mt-1 mb-1 sm:mt-0 sm:mb-0"
                              data-tip={t("constant.Delete document")}
                              onClick={() => setIsDeleteModalOpen(true)}
                              style={{ width: "40px" }} // Ensure consistent width
                            >
                              <img src={DeleteIcon} width={20} />
                            </button>
                          ) : (
                            <button
                              className="btn btn_theme btn-sm w-10 mt-1 mb-1 sm:mt-0 sm:mb-0 invisible"
                              style={{ width: "40px" }} // Ensure consistent width
                            >
                              <img src={DeleteIcon} width={20} />
                            </button>
                          )}
                        </span>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
      <MoveRepoModallegacy
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        repos={repos}
        selectedIds={selectedIds}
        setPageLoading={setPageLoading}
      />
      <DeleteModal
        title={t("allModule.Delete Contracts")}
        bodyText={t(
          "allModule.Are you sure you want to delete these contracts?"
        )}
        isDeleteModalOpen={deleteModalOpen}
        setIsDeleteModalOpen={setDeleteModalOpen}
        deleteContracts={deleteContracts}
      />
      <PlayriteDiligenceLegacy
        isModalOpen={isplayriteModalOpen}
        setIsModalOpen={setisplayriteModalOpen}
        file={selecteddocs}
        uploadQuoqoDeligence={uploadQuoqoDeligence}
        sectionTaskList={sectionTaskList}
        countryListData={countryListData}
        sectionList={sectionList}
        playBookList={playBookList}
        getSectionTaskList={getSectionTaskList}
        inputRef={inputRef}
        setSectionTaskList={setSectionTaskList}
        setPageLoading={setPageLoading}
      />
      <ReportListModal
        isReportsModalOpen={isReportsModalOpen}
        setIsReportsModalOpen={setIsReportsModalOpen}
        reportLists={reportLists}
        docName={docName}
      />
    </>
  );
};

export default ContractsTablelegacy;
