import React, { useEffect, useContext, useState, useRef } from "react";
import Select from "react-select";
import { Editor } from "@tinymce/tinymce-react";
import { resolve } from "../../api/resolve";
import useAxios from "../../hooks/useAxios";
import LoadingScreen from "../loader/LoadingScreen";
import AppContext from "../../context/AppContext";
import { toast } from "react-toastify";
import PermissionContext from "../../context/PermissionContext";
import ClauseAI from "./clauseai";
import DocChatAI from "./docChatAI";
import ClauseLibraryChat from "./ClauseLibraryChat";
import { useTranslation } from "react-i18next";
import LegalConfirmModal from "../modals/LegalConfirmModal";
import ReactTooltip from "react-tooltip";
import dayjs from "dayjs";
import DeleteIcon from "../../assets/icons/Delete.svg";
import DeleteModal from "../modals/DeleteModal";
import aiOrange from "../../assets/icons/ai_orange.svg";
import aiPurple from "../../assets/icons/ai_purple.svg";
import SearchPopup from "./SearchPopup";
import QuoqoOnlineEditor from "./QuoqoOnlineEditor";

import QChat from "./QChat";

const API_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

function getCsrfToken() {
  const name = "csrftoken";
  const cookieString = document.cookie || "";
  const cookies = cookieString.split("; ");
  const csrfToken = cookies.find((row) => row.startsWith(name));

  if (!csrfToken) {
    console.error(
      "CSRF token not found. Ensure it is set correctly in your cookies."
    );
    return "";
  }

  return csrfToken.split("=")[1];
}

function launchChatWithText(selectedText) {
  fetch(`${API_BASE_URL}/contract/start-chat/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": getCsrfToken(),
    },
    body: JSON.stringify({
      searchText: selectedText,
      aiContent: "",
      conversationHistory: [],
    }),
  })
    .then((response) => {
      if (!response.ok) {
        return response.text().then((text) => {
          throw new Error(text);
        });
      }
      return response.json();
    })
    .then((data) => {
      alert(JSON.stringify(data, null, 2));
    })
    .catch((error) => {
      console.error("Error:", error);
      alert(`There was an error: ${error.message}`);
    });
}

const AZURE_OPENAI_API_KEY = "4581c30403f847b7add57bbd9d324aba";
const AZURE_OPENAI_ENDPOINT =
  "https://q-chatgpt.openai.azure.com/openai/deployments/gpt-4o/chat/completions?api-version=2024-05-01-preview";



const GenerateDocument = () => {
  const [loading, setLoading] = useState(false);
  const [templateName, setTemplateName] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const editorRef = useRef(null); // <-- Define the editorRef here
  const [documentData, setDocumentData] = useState(() => {
    return localStorage.getItem("generateDocumentContent") || "";
  });
  const [search, setSearch] = useState("");
  const [searchDraft, setSearchDraft] = useState("");
  const [aiContent, setAiContent] = useState("");
  const [apicalled, setApicalled] = useState(false);
  const [apiResponse, setApiResponse] = useState("");
  const { t } = useTranslation();
  const [docchat, setDocchat] = useState(true);
  const [draftList, setDraftList] = useState([]);
  const [templateList, setTemplateList] = useState([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState("");
  const [deleteTemplateId, setDeleteTemplateId] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [clauseList, setClauseList] = useState([]);
  const [selectedText, setSelectedText] = useState("");
  const [isPopupOpen, setPopupOpen] = useState(false);

  const handleOpenPopup = () => setPopupOpen(true);
  const handleClosePopup = () => setPopupOpen(false);

  const textareaRef = useRef(null);

  const {
    activeLink,
    setActiveLink,
    setPrevActiveLink,
    setShowDropdown,
    setTempSaveConfirmation,
    templateConfirmationOpen,
    setTemplateConfirmationOpen,
  } = useContext(AppContext);

  const {
    permissionList,
    getComapnyInfoApi,
    isValidCompany,
    updatePermissionList,
  } = useContext(PermissionContext);

  const baseURL = process.env.REACT_APP_BACKEND_BASE_URL;
  const api = useAxios();

  const getClauseListApi = async () => {
    try {
      const responseData = await resolve(
        api.get(`${baseURL}/contract/list/clause/`).then((res) => res.data)
      );
      if (responseData["data"] && responseData["data"].results) {
        const updatedList = responseData["data"].results.map((item) => {
          let clauseTextArray;
          try {
            clauseTextArray = JSON.parse(item.clause_text.replace(/'/g, '"'));
          } catch (error) {
            console.error("Error parsing clause_text:", error);
            clauseTextArray = [];
          }
          return { ...item, clause_text: clauseTextArray };
        });
        setClauseList(updatedList);
      }
    } catch (error) {
      console.error("Error fetching clause library list:", error);
    }
  };

  const handleSearch = async () => {
    if (search === "") {
      toast.error(t("Please enter a contract description"));
      return;
    }
    setDocumentData("");
    handleClosePopup();
    setLoading(true);
    if (!checkIfContract(search)) {
      setLoading(false);
      return;
    }

    try {
      const responceData = await resolve(
        api
          .post(`/contract/generate/`, {
            prompt: search,
            template_id: selectedTemplateId,
          })
          .then((res) => res.data)
      );

      if (responceData.data) {
        const textData = responceData.data;
        const paragraphs = textData.split("\n\n");
        const sanitizedText = paragraphs
          .map((paragraph) => `<p>${paragraph.replace(/\n/g, "<br>")}</p>`)
          .join("");

        setDocumentData(sanitizedText); // Update the TinyMCE editor content here
        setApiResponse(sanitizedText);
        setApicalled(false);
        setApicalled(true);
      } else if (responceData.error) {
        console.log("Error generating document 1:", responceData.error);
        toast.error(t("common.Something went wrong"));
      } else {
        console.log("Error generating document 2:", responceData);
        toast.error(t("common.Something went wrong"));
      }
    } catch (error) {
      console.log("Error generating document 3:", error);
      toast.error(t("common.Something went wrong"));
    } finally {
      setLoading(false);
    }
  };

  const getTemplateListApi = async () => {
    const type = "template";
    const responceData = await resolve(
      api
        .get(`${baseURL}/contract/template/list/?type=${type}`)
        .then((res) => res.data)
    );

    if (responceData["data"]) {
      const sortedTemplates = responceData["data"].data.sort((a, b) =>
        a.title.localeCompare(b.title)
      );

      setTemplateList(responceData["data"].data);
    }
  };

  const templateOptions = templateList.map((template) => ({
    value: template.id,
    label: template.title,
  }));

  const checkIfContract = (prompt) => {
    const keywords = [
      "Accord",
      "Accordance",
      "Agreement",
      "Arrangement",
      "Articles of Agreement",
      "Bargain",
      "Bond",
      "Charter",
      "Compact",
      "Commitment",
      "Concord",
      "Consensus",
      "Contract",
      "Convention",
      "Covenant",
      "Deal",
      "Deed",
      "Engagement",
      "Instrument",
      "Lease",
      "License",
      "Memorandum",
      "Memorandum of Understanding (MOU)",
      "MOU",
      "NDA (Non-Disclosure Agreement)",
      "NDA",
      "Obligation",
      "Pact",
      "Protocol",
      "Resolution",
      "Settlement",
      "Stipulation",
      "Terms",
      "Terms and conditions",
      "Terms of Agreement",
      "Terms of Service",
      "Treaty",
      "Understanding",
    ];
    const hasContractKeyword = keywords.some((keyword) =>
      prompt.toLowerCase().includes(keyword.toLowerCase())
    );

    if (hasContractKeyword) {
      return true;
    } else {
      toast.error(
        t(
          "allModule.The Document Assistant can only be used to draft contracts or agreements."
        )
      );
      return false;
    }
  };

  const handleTemplateClick = async (item) => {
    setDocumentData("");
    setLoading(true);
    const res = await resolve(
      api
        .get(`${baseURL}/contract/template/list/view/${item.id}`)
        .then((res) => {
          setApiResponse(res["data"] + "");
          setApicalled(false);
          setApicalled(true);

          if (res["data"]) {
            // setDocumentDataDefault(responceData["data"] + "");
            setDocumentData(res["data"] + "");
            setLoading(false);
          }

          if (res["error"]) {
            toast.error(t("common.Something went wrong"));
            // setLoading(false);
          }
          return res.data;
        })
    );
  };

  const getDraftListApi = async () => {
    const type = "draft";
    const responceData = await resolve(
      api
        .get(`${baseURL}/contract/template/list/?type=${type}`)
        .then((res) => res.data)
    );
    if (responceData["data"]) {
      setDraftList(responceData["data"].data);
    }
  };

  const getTemplateSearchListApi = async (search) => {
    const type = "draft";
    const payload = {
      search: search,
      type: type,
    };

    const responceData = await resolve(
      api
        .get(`${baseURL}/contract/template/list/search/`, { params: payload })
        .then((res) => res.data)
    );
    if (responceData["data"] && responceData["data"].results) {
      setDraftList(responceData["data"].results);
    }
  };

  const deleteTemplate = async (item) => {
    const responceData = await resolve(
      api
        .get(`${baseURL}/contract/template/list/delete/${deleteTemplateId}`)
        .then((res) => res.data)
    );
    if (responceData["data"]) {
      toast.success(t("allModule.Template deleted successfully"));
      getDraftListApi();
      setIsDeleteModalOpen(false);
      // getDefaultTemplateListApi();
    }

    if (responceData["error"]) {
      toast.error(t("allModule.Delete template failed"));
    }
    setIsDeleteModalOpen(false);
  };

  const handleClearBtn = () => {
    setSearch("");
    setDocumentData("");
    setApiResponse("");
    setTemplateName("");
  };

  const replaceSelectedText = (text) => {
    if (editorRef.current) {
      editorRef.current.selection.setContent(text);
    }
  };

  const handleSaveTemplate = async (type) => {
    const content = documentData;

    setErrorMsg("");
    if (templateName === "") {
      setErrorMsg(t("Please provide template name"));
    } else if (content === "") {
      setErrorMsg(t("Please provide template content"));
    } else {
      setErrorMsg("");
      setLoading(true);
      const postData = {
        templateName: templateName,
        content: content,
        createTemplate: true,
        createDocuement: false,
        type: type,
        fileType: "docx",
        remarks: "Created from Generate Document",
      };
      const res = await resolve(
        api
          .post(`${baseURL}/contract/template/converter/`, postData)
          .then((res) => {
            setLoading(false);
            if (res["data"]) {
              setApiResponse("");
              const msg =
                (type === "template" ? "Template" : "Draft") +
                " saved successfully";
              toast.success(t(msg));
              setApicalled(false);
              setApicalled(true);
              setTemplateName("");
              setDocumentData("");
              localStorage.removeItem("createTemplateContent");
              setTempSaveConfirmation(false);
              getTemplateListApi();
              getDraftListApi();
              updatePermissionList(res["data"].User_permission[0]);
            }
            return res.data;
          })
          .catch((error) => {
            setLoading(false);
            setApicalled(false);
            setApicalled(true);
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              setApiResponse(content);
              toast.error(error.response.data.message); // Displaying the custom error message
            }
          })
      );
    }
  };

  const handleEditorInit = (evt, editor) => {
    editorRef.current = editor;
    editor.on("SelectionChange", () => {
      const selectedContent = editor.selection.getContent();
      setSelectedText(selectedContent);
    });
  };

  const handleSaveDocument = async (fileType) => {
    const content = documentData;
    setErrorMsg("");
    if (templateName === "") {
      setErrorMsg(t("allModule.Please provide document name"));
    } else if (content === "<p><br></p>") {
      setErrorMsg(t("allModule.Please provide document content"));
    } else {
      setLoading(true);

      const postData = {
        templateName: templateName,
        content: content,
        createTemplate: false,
        createDocuement: true,
        type: "ai",
        fileType: fileType,
      };
      const responceData = await resolve(
        api
          .post(`${baseURL}/contract/template/converter/`, postData)
          .then((res) => res.data)
      );

      setLoading(false);
      if (responceData["data"]) {
        setDocumentData("");
        localStorage.removeItem("generateDocumentContent");
        toast.success(t("allModule.Document created successfully"));
        setApiResponse("");
        setApicalled(false);
        setApicalled(true);
        setErrorMsg("");
        setTemplateName("");
        setTempSaveConfirmation(false);
        setActiveLink("MyDocuments");
        updatePermissionList(responceData["data"].User_permission[0]);
      }

      if (responceData["error"]) {
        setApiResponse(content);
        setLoading(false);
        setApicalled(false);
        setApicalled(true);
        toast.error(t("allModule.Create document failed"));
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    getComapnyInfoApi();
    getDraftListApi();
    getTemplateListApi();
    getClauseListApi();
    setTempSaveConfirmation(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  // Function to accept changes only for selected text
  // function acceptChangesForSelection(editor) {
  //   editor.undoManager.transact(function () {
  //     const selectedContent = editor.selection.getContent({ format: "html" });
  //     if (!selectedContent) {
  //       console.log("No text selected. Cannot accept changes.");
  //       return;
  //     }

  //     // Remove strikethrough text (deleted text)
  //     let newContent = selectedContent.replace(
  //       /<span style="color: red; text-decoration: line-through;">(.*?)<\/span>/g,
  //       ""
  //     );

  //     // Convert redlined text (added text) to normal text
  //     newContent = newContent.replace(
  //       /<span style="color: red; text-decoration: underline;">(.*?)<\/span>/g,
  //       "$1"
  //     );

  //     // Replace the selected content with the updated content
  //     editor.selection.setContent(newContent);

  //     // Log changes for debugging
  //     console.log(
  //       "Accepted changes for selected text. New content:",
  //       newContent
  //     );
  //   });
  // }

  return (
    <>
      <div className="text-primary text-base font-normal font-sans mt_set">
        <div
          className="main_head"
          style={{ display: "flex", alignItems: "center" }}
        >
          <h1 className="text-4xl font-bold text_c">
            {t("menu.Generate Document")}
          </h1>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "1rem",
            }}
          >
            <button
              className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3"
              onClick={handleOpenPopup}
            >
              {t("menu.Generate Document")}
            </button>
            <SearchPopup
              isOpen={isPopupOpen}
              onClose={handleClosePopup}
              handleSearch={handleSearch}
              search={search}
              setSearch={setSearch}
              templateOptions={templateOptions}
              selectedTemplateId={selectedTemplateId}
              setSelectedTemplateId={setSelectedTemplateId}
              t={t}
              docchat={docchat}
              setDocchat={setDocchat}
              aiPurple={aiPurple}
              aiOrange={aiOrange}
            />
            <button
              onClick={(e) => {
                e.preventDefault();
                setDocchat(!docchat);
              }}
              className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3 ml-5"
              style={{
                height: "fit-content",
                alignSelf: "center",
                width: "max-content",
              }}
              data-tip="AI Assistant and Document Chat"
            >
              <img
                src={docchat ? aiPurple : aiOrange}
                alt="AI"
                className="tab-icon"
                style={{ width: "40px", height: "24px" }}
              />
            </button>
          </div>
        </div>

        {!isValidCompany ? (
          <div className="flex justify-center items-center h-96 flex-column ">
            <h3 className="text-2xl font-bold text_c">
              {t("allModule.Please update your company info.")}
            </h3>
          </div>
        ) : permissionList["ai_template_on"] == 1 ? (
          <>
            {permissionList.ai_template_allotted_cnt > 0 &&
            permissionList.ai_template_remaing_cnt === 0 ? (
              <div className="flex justify-center items-center h-96 flex-column ">
                <h3 className="text-2xl font-bold text_c">
                  {t("allModule.You have no more limit to generate document.")}
                </h3>
              </div>
            ) : (
              <div className="w-full items-center generate_docuement">
                <div className="row bottom_area">
                  <div className="col-md-9">
                    {loading ? (
                      <LoadingScreen />
                    ) : (
                      <>
                        <div
                          className="bottom_area"
                          style={{
                            flex: "3",
                            flexDirection: "row",
                            width: "100%",
                            // height: "70vh",
                          }}
                        >
                          <QuoqoOnlineEditor
                            value={documentData}
                            onEditorChange={(content) =>
                              setDocumentData(content)
                            }
                            handleEditorInit={handleEditorInit}
                            clauseList={clauseList}
                          />
                        </div>

                        <div className="flex justify-end">
                          <p className="error_msg">{errorMsg}</p>
                        </div>

                        <div className="mt-3 mb-3 flex justify-end set_mb1">
                          <input
                            type="text"
                            value={templateName}
                            onChange={(e) => setTemplateName(e.target.value)}
                            className="border-2 border-primary rounded-lg outline-none  pl-2 mt-3"
                            placeholder={t("allModule.Enter Document Name")}
                          />
                          <button
                            onClick={(e) => {
                              handleSaveDocument("docx");
                            }}
                            data-tip={t("constant.Save as docx for Editing")}
                            className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3 ml-5 mt-3"
                          >
                            {t("constant.Save as docx")}
                          </button>
                          <button
                            onClick={(e) => {
                              handleSaveDocument("pdf");
                            }}
                            data-tip={t("constant.Save as pdf for Signature")}
                            className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3 ml-5 mt-3"
                          >
                            {t("constant.Save as pdf")}
                          </button>
                          <button
                            onClick={(e) => {
                              handleSaveTemplate("template");
                            }}
                            data-tip={t("allModule.Save as Template")}
                            className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3 ml-5 mt-3"
                          >
                            {t("allModule.Save as Template")}
                          </button>
                          <button
                            onClick={(e) => {
                              handleSaveTemplate("draft");
                            }}
                            data-tip={t("constant.Save as Draft")}
                            className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3 ml-5 mt-3"
                          >
                            {t("constant.Save as Draft")}
                          </button>
                          <ReactTooltip
                            backgroundColor="#400835"
                            textColor="#ffffff"
                          />
                        </div>
                      </>
                    )}
                  </div>
                  <div className="col-md-3">
                    <div
                      style={{
                        flex: "3",
                        display: "fixed",
                        height: "70vh",
                      }}
                    >
                      {!loading &&
                        (docchat ? (
                          <div className="default-content">
                            <div>
                              <h4 className="text-xl font-bold mb-2 text_c ">
                                {t("Select Draft")}
                              </h4>
                              <div className="seachbox mb-2">
                                <input
                                  type="text"
                                  className="border-2 border-primary rounded-lg outline-none p-1 pl-2 w-2/3"
                                  placeholder={t("Search a Draft")}
                                  onChange={(e) => {
                                    setSearchDraft(e.target.value);
                                  }}
                                  onKeyUp={(e) => {
                                    if (
                                      e.key === "Enter" ||
                                      e.target.value === ""
                                    ) {
                                      getTemplateSearchListApi(searchDraft);
                                    }
                                  }}
                                  value={searchDraft}
                                />
                                <button
                                  className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3"
                                  onClick={(e) =>
                                    getTemplateSearchListApi(searchDraft)
                                  }
                                >
                                  {t("allModule.Search")}
                                </button>
                              </div>
                              <div className="template_list_container pad_0">
                                {draftList.length > 0 &&
                                  draftList.map((item, index) => {
                                    const titleWithoutExtension =
                                      item.title.replace(
                                        /\.(docx|pdf|doc)$/,
                                        ""
                                      );
                                    return (
                                      <>
                                        <ReactTooltip
                                          backgroundColor="#400835"
                                          textColor="#ffffff"
                                        />
                                        <div
                                          key={item.id + "-" + item.title}
                                          onClick={(e) =>
                                            handleTemplateClick(item)
                                          }
                                          className="mr-1 mb-1 template_box"
                                        >
                                          <div className="template_box_head">
                                            <h1 className="text-2xl font-bold text_c">
                                              {item.title}
                                            </h1>
                                          </div>
                                          <div className="template_box_body">
                                            <strong>
                                              {t("allModule.Created on")}
                                              {": "}
                                            </strong>
                                            <span className="text-base font-normal text_c">
                                              {dayjs(item.created_at).format(
                                                "dddd, MMM D, YYYY HH:mm"
                                              )}
                                            </span>
                                          </div>
                                          <button
                                            className="btn btn_theme btn-sm mr-1 mt-8 wh_spab"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              setDeleteTemplateId(item.id);
                                              setIsDeleteModalOpen(true);
                                            }}
                                            data-tip={t(
                                              "constant.Delete document"
                                            )}
                                          >
                                            <img
                                              src={DeleteIcon}
                                              className="ml-0.5"
                                              width={20}
                                              alt="Delete Document"
                                            />
                                          </button>
                                        </div>
                                      </>
                                    );
                                  })}

                                {draftList.length === 0 && (
                                  <div className="flex justify-center items-center h-96 flex-column w-full">
                                    <h3 className="text-2xl font-bold text_c">
                                      {t("allModule.No Template Found")}
                                    </h3>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <QChat
                            documentData={documentData}
                            setDocumentData={setDocumentData}
                            selectedText={selectedText}
                            replaceSelectedText={replaceSelectedText}
                            className="chat-window"
                          />
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="flex justify-center items-center h-96 flex-column ">
            <h3 className="text-2xl font-bold text_c">
              You don't have permission to access this page
            </h3>
          </div>
        )}
      </div>
      <DeleteModal
        title="Delete Template"
        bodyText="Are you sure you want to delete template?"
        isDeleteModalOpen={isDeleteModalOpen}
        setIsDeleteModalOpen={setIsDeleteModalOpen}
        deleteTemplate={deleteTemplate}
      />
      <LegalConfirmModal
        title={t("allModule.Confirmation")}
        bodyText={t(
          "allModule.Please save your changes before moving out of this page!"
        )}
        isConfirmModalOpen={templateConfirmationOpen}
        setIsConfirmModalOpen={setTemplateConfirmationOpen}
        setTempSaveConfirmation={setTempSaveConfirmation}
        activeLink={activeLink}
        setPrevActiveLink={setPrevActiveLink}
      />
    </>
  );
};

export default GenerateDocument;
