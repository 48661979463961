import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import "./clauseai.css";
import copy from "../../assets/icons/copy-clause.png";
import useAxios from "../../hooks/useAxios";
import LoadingScreen from "../loader/LoadingScreen";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import { resolve } from "../../api/resolve";
import Select from "react-select";
import modifyIcon from "../../assets/icons/edit_icon.svg";

function htmlToPlainText(html) {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;
  return tempDiv.textContent || tempDiv.innerText || "";
}

const ClauseLibaryChat = React.forwardRef((props, ref) => {
  const { documentData, selectedText, replaceSelectedText, className } = props;
  const [searchText, setSearchText] = useState("");
  const [isResizing, setResizing] = useState(false);
  const [recommendations, setRecommendations] = useState([]);
  const { t } = useTranslation();
  const [selectedRecommendation, setSelectedRecommendation] = useState("");
  const [hasContent, setHasContent] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [copiedText, setCopiedText] = useState(null);
  const [conversationHistory, setConversationHistory] = useState([]);
  const baseURL = process.env.REACT_APP_BACKEND_BASE_URL;
  const api = useAxios();
  const prevMousePos = useRef({ x: 0, y: 0 });

  const [clauseLibaryList, setClauseLibaryList] = useState([]);
  const [selectedClauseName, setSelectedClauseName] = useState(null);
  const [clauseTextOptions, setClauseTextOptions] = useState([]);
  const [selectedClauseText, setSelectedClauseText] = useState("");

  // Fetch Clause Library List from the backend
  const fetchClauseLibaryList = async () => {
    try {
      const responseData = await resolve(
        api.get(`${baseURL}/contract/list/clause/`).then((res) => res.data)
      );
      if (responseData["data"] && responseData["data"].results) {
        const updatedList = responseData["data"].results.map((item) => {
          let clauseTextArray;
          try {
            clauseTextArray = JSON.parse(item.clause_text.replace(/'/g, '"'));
          } catch (error) {
            console.error("Error parsing clause_text:", error);
            clauseTextArray = [];
          }
          return { ...item, clause_text: clauseTextArray };
        });
        setClauseLibaryList(updatedList);
      }
    } catch (error) {
      console.error("Error fetching clause library list:", error);
    }
  };

  useEffect(() => {
    fetchClauseLibaryList();
  }, []);

  const clauseOptions = clauseLibaryList.map((clause) => ({
    value: clause.clause_name,
    label: clause.clause_name,
  }));

  // Handle when a clause is selected
  const handleClauseNameChange = (selectedOption) => {
    setSelectedClauseName(selectedOption);

    const selectedClause = clauseLibaryList.find(
      (item) => item.clause_name === selectedOption.value
    );

    if (selectedClause) {
      setClauseTextOptions(selectedClause.clause_text);
    } else {
      setClauseTextOptions([]);
    }

    setRecommendations([]);
  };

  const handleModifyClick = async (textToModify) => {
    replaceSelectedText(textToModify);
  };

  const handleSearch = async () => {
    try {
      setLoading(true);
      const aiContent = htmlToPlainText(documentData);
      const plain_selectedClauseText = htmlToPlainText(selectedClauseText);
      if (!aiContent || !plain_selectedClauseText) {
        toast.error("Please provide context");
        return;
      }

      const payload = {
        aiContent: aiContent,
        selectedClauseText: plain_selectedClauseText,
      };

      const response = await axios.post(
        `${baseURL}/contract/generate/clause/`,
        payload
      );

      if (response.status === 200) {
        const recommendations = response.data;
        setRecommendations(Object.values(recommendations));
        setConversationHistory([
          ...conversationHistory,
          { user: searchText, assistant: recommendations },
        ]);
      } else {
        throw new Error("Failed to fetch recommendations");
      }
    } catch (error) {
      console.error("Error fetching recommendations:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCopyClick = (htmlContent) => {
    function listener(e) {
      e.clipboardData.setData("text/html", htmlContent);
      e.clipboardData.setData("text/plain", htmlContent);
      e.preventDefault();
    }

    document.addEventListener("copy", listener);

    try {
      document.execCommand("copy");
      document.removeEventListener("copy", listener);
      toast.success("Copied to clipboard");
    } catch (error) {
      console.error("Failed to copy text: ", error);
      toast.error("Failed to copy to clipboard");
    }
  };

  const handleCancelClick = () => {
    setRecommendations([]);
    setHasContent(false);
    setSearchText("");
    setConversationHistory([]);
  };

  return (
    <div className={`chat-clauselibrary ${className}`}>
      <div className="clause-container">
        <button className="cancel-button" onClick={handleCancelClick}>
          x
        </button>
        <div className="clause-title">
          <h2 style={{ fontWeight: "bold", fontSize: "1.2em" }}>
            {t("constant.Clause Library")}
          </h2>
        </div>

        <div className="mt-3 Libary-container">
          <Select
            value={selectedClauseName}
            onChange={handleClauseNameChange}
            options={clauseOptions}
            className="w-full text-primary rounded-lg font-semibold"
            placeholder={t("constant.Select a clause")}
            isSearchable={true}
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 99999, // Ensures the dropdown menu is on top
                height: "25vh", // Adjust the height as needed to display 4 clauses
                overflowY: "auto", // Enables scrolling if there are more than 4 clauses
              }),
            }}
          />

          {clauseTextOptions.length > 0 && (
            <div className="m-2 font-medium overflow-y-auto clauseoption">
              {clauseTextOptions.map((text, index) => (
                <div
                  key={index}
                  className="mb-2"
                  style={{ color: copiedText === text ? "#310128" : "black" }}
                >
                  <input
                    type="radio"
                    id={`clause_text_${index}`}
                    name="clause_text"
                    value={text}
                    className="mr-2 align-middle"
                    onChange={(e) => setSelectedClauseText(e.target.value)}
                    checked={selectedClauseText === text}
                  />
                  <label
                    htmlFor={`clause_text_${index}`}
                    className="text-gray-700 inline"
                  >
                    {text}
                  </label>
                  <button
                    onClick={() => handleCopyClick(text)}
                    data-tip={t("Copy")}
                    className="ml-2 inline-block align-baseline"
                    style={{ verticalAlign: "baseline" }}
                  >
                    <img
                      src={copy}
                      alt="Copy Icon"
                      className="copy-icon"
                      style={{ width: "20px", height: "20px" }}
                    />
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="flex justify-end mt-2 mb-2 ">
          <button
            onClick={handleSearch}
            className="px-4 py-2 bg-primary text-white rounded-md"
            data-tip={t("allModule.Modify Clause as per Document")}
          >
            {t("allModule.Modify")}
          </button>
          <ReactTooltip backgroundColor="#400835" textColor="#ffffff" />
        </div>

        {isLoading ? (
          <LoadingScreen />
        ) : (
          <div className="recommendations-container-libary">
            {recommendations.map((recommendation, index) => (
              <React.Fragment
                key={`${recommendation.id || recommendation}-${index}`}
              >
                <h3 style={{ fontWeight: "bold", fontSize: "1em" }}>
                  {t("constant.Modifed Clause")}:
                </h3>
                <div className="recommendation-item">
                  <span
                    style={{
                      color:
                        copiedText === recommendation ? "#310128" : "black",
                      fontWeight:
                        copiedText === recommendation ? 500 : "normal",
                    }}
                  >
                    {htmlToPlainText(recommendation)}
                  </span>
                  <button
                    data-tip={t("Copy")}
                    onClick={() => handleCopyClick(recommendation)}
                  >
                    <img
                      src={copy}
                      alt="Copy Icon"
                      className="copy-icon"
                      style={{ width: "22px", height: "22px" }}
                    />
                  </button>
                  <button
                    onClick={() => handleModifyClick(recommendation)}
                    data-tip="Insert into Document"
                    className="copy-button"
                    style={{
                      border: "none",
                      background: "transparent",
                      padding: "0",
                      margin: "0",
                    }}
                  >
                    <img
                      src={modifyIcon}
                      alt="Modify Icon"
                      className="copy-icon"
                      style={{ width: "19px", height: "19px" }}
                    />
                  </button>
                </div>
              </React.Fragment>
            ))}
          </div>
        )}
      </div>

      <ReactTooltip backgroundColor="#400835" textColor="#ffffff" />
    </div>
  );
});

export default ClauseLibaryChat;
