import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from '../../context/AuthContext';
import appLogo from '../../assets/app-logo.svg';
import micIconss from '../../assets/Microsoft_logo.svg.png';
import eye_off from '../../assets/icons/eye_off.png';
import eye_on from '../../assets/icons/eye_on.png';
import { useTranslation } from 'react-i18next';


const LoginForm = ({ formErrors, onSubmit, googleLogin, microsoftUserLogin, MicrosoftLogin, openModal }) => {
  const { loading } = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isShowPassword, setIsShowPassword] = useState(false);
  const { t } = useTranslation();

  const onFormSubmit = (e) => {
    e.preventDefault();
    onSubmit(email, password);
  };

  return (
    <div className=' w-full flex flex-col items-center'>
      <Link to='/'>
        <img
          src={appLogo}
          alt='app-logo'
          style={{ maxWidth: '200px', maxHeight: '200px' }}
        />
      </Link>
      <div className='mt-6 p3-2 pl-5 pr-5 p3-2   form-container'>
        <p className='text-2xl font-bold text-center  text-primary m-3 mb-5'>
          {t('loginPage.Quoqo offers the best legal automation and AI solutions to enterprises, globally')}.
        </p>
        <form className='w-full' onSubmit={(e) => onFormSubmit(e)}>
          <div className='mb-3'>
            <label className='text-primary font-bold'>{t('common.Email')}</label>
            <input
              type='text'
              placeholder={t('loginPage.Enter email address')}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className='w-full p-1 pl-3 focus:outline-none text-primary'
            />
            <p className='text-sm text-red-500'>{formErrors.email}</p>
          </div>
          <div className='mb-5'>
            <label className='text-primary font-normal'>{t('common.Password')}</label>
            <div className='password-container'>
              <input
                type={isShowPassword ? 'text' : 'password'}
                placeholder='*******'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className='w-full p-1 pl-3 focus:outline-none text-primary'
                autoComplete='off'
              />
              <img src={isShowPassword ? eye_on : eye_off} onClick={() => setIsShowPassword(!isShowPassword)} className='eye-icon' />
            </div>

            <p className='text-sm text-red-500'>{formErrors.password}</p>
          </div>
          <button
            type='submit'
            className={`bg-primary ${loading && 'bg-gray-500'} hover:bg-secondary text-white p-1 pl-20 pr-20 font-medium w-full mb-3`}

          >
            {t('loginPage.Login')}
          </button>
          <Link to='/forgot-password'>
            <p className='text-center text-red-600 font-bold flex justify-center mb-3'>
              <span>*</span>{t('loginPage.Forgot your password')}?
            </p>
          </Link>
          <div className='row'>
            <div className='col-md-12 mb-2'>
              <div
                onClick={() => { openModal() }}
                className="google-login-btn " >
                <p><b>{t('loginPage.Sign up for an Account')}</b></p>
              </div>
            </div>
            <div className='col-md-12 mb-2'>
              <div
                onClick={() => { googleLogin() }}
                className="google-login-btn" >
                <svg aria-hidden="true" className="native svg-icon iconGoogle" width="22" height="22" viewBox="0 0 18 18"><path fill="#4285F4" d="M16.51 8H8.98v3h4.3c-.18 1-.74 1.48-1.6 2.04v2.01h2.6a7.8 7.8 0 0 0 2.38-5.88c0-.57-.05-.66-.15-1.18Z"></path><path fill="#34A853" d="M8.98 17c2.16 0 3.97-.72 5.3-1.94l-2.6-2a4.8 4.8 0 0 1-7.18-2.54H1.83v2.07A8 8 0 0 0 8.98 17Z"></path><path fill="#FBBC05" d="M4.5 10.52a4.8 4.8 0 0 1 0-3.04V5.41H1.83a8 8 0 0 0 0 7.18l2.67-2.07Z"></path><path fill="#EA4335" d="M8.98 4.18c1.17 0 2.23.4 3.06 1.2l2.3-2.3A8 8 0 0 0 1.83 5.4L4.5 7.49a4.77 4.77 0 0 1 4.48-3.3Z"></path></svg>
                <p>{t('loginPage.Sign in with Google')}</p>
              </div>
            </div>
            <div className='col-md-12'>
              <div className="google-login-btn" >
                <img width="20" height="20" src={micIconss} />
                <p>{t('loginPage.Sign in with Microsoft')} </p>
                <div style={{ color: 'red' }} className='MicrosoftLogin2'>
                  <MicrosoftLogin clientId={process.env.REACT_APP_MICROSOFT_CLIENT_ID} authCallback={microsoftUserLogin} />
                </div>
              </div>

            </div>

          </div>



        </form>
      </div>
    </div>
  );
};

export default LoginForm;
